
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router';
import {surveyEndpoints} from '../../services/ApiEndPoints'
import { useSelector } from 'react-redux'; // To get the token from Redux store
import { apiConnector } from '../../services/ApiConnector';
import {toast} from 'react-hot-toast'

const UserTable = ({id}) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token); // Get token from Redux store
  const [users, setUsers] = useState([]); // Data state
  const [loading, setLoading] = useState(false); // Loading state
  const [page, setPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1); // Total pages from API
  const [totalResponseRecord,setTotalResponseRecord] = useState(null);
  
  // API call to fetch users
  const fetchUsers = async (currentPage = 1, reset = false) => {
    setLoading(true);
    try {
      const response = await apiConnector(
        'GET',
        `${surveyEndpoints.GET_ALL_SURVEYRESPONSE}?surveyId=${id}&page=${currentPage}&limit=3`, // Adjust the endpoint and query params accordingly
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      console.log(response)
      if (reset) {
        setUsers(response.data?.responses); // Replace data if reset is true
      } else {
        setUsers((prevUsers) => [...prevUsers, ...response.data?.responses]); // Append new data to existing
      }
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch on component mount
  useEffect(() => {
    fetchUsers(1, true); // Fetch the first page initially
  }, []);

  // Load more when page changes
  useEffect(() => {
    if (page > 1) {
      fetchUsers(page);
    }
  }, [page]);

  // Handle the Load More button click
  const handleLoadMore = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1); // Increment the page number
    }
  };


  function createExcelFromData(data) {
    const toastId = toast.loading("Saving your Excel File. Please wait!");
    
    // Initialize the Excel data array
    const excelData = [];
    
    // Extract all unique question titles
    const questionTitles = data.flatMap(entry => 
      entry.responses.map(response => response.questionId.title)
    );
    const uniqueQuestionTitles = [...new Set(questionTitles)];
  
    // Add headers to Excel data, including additional fields
    const headers = [
      'userId', 
      'name', 
      'type', 
      ...uniqueQuestionTitles, 
      'totalCorrect', 
      'totalIncorrect', 
      'totalMarks', 
      'totalMarksObtained', 
      'submittedAt', 
      'surveyId'
    ];
    excelData.push(headers);
  
    // Loop through the data array to populate rows
    data.forEach(entry => {
      const row = [];
  
      // Determine type and userId based on teacherId or studentId
      if (entry.teacherId) {
        row.push(entry.teacherId.userId || ''); // userId
        row.push(entry.teacherId.name || '');   // name
        row.push('teacher');                    // type
      } else if (entry.studentId) {
        row.push(entry.studentId.userId || ''); // userId
        row.push(entry.studentId.name || '');   // name
        row.push('student');                    // type
      }else if(entry?.councellerId){
        row.push(entry?.councellerId?._id || ''); // userId
        row.push(entry?.councellerId?.name || '');   // name
        row.push('counsellor'); 
      } else {
        row.push(''); // default for userId
        row.push(''); // default for name
        row.push(''); // default for type
      }
  
      // Create a map of answers for quick lookup, handling array answers for multiple-choice questions
      const answersMap = entry.responses.reduce((map, response) => {
        const answer = Array.isArray(response.answer)
          ? response.answer.join(', ') // Join array answers with commas
          : response.answer || '';
        map[response.questionId.title] = answer;
        return map;
      }, {});
  
      // Add answers in the corresponding question columns
      const answers = uniqueQuestionTitles.map(title => answersMap[title] || '');
      row.push(...answers);
  
      // Add additional details to the row
      row.push(entry.totalCorrect || '');
      row.push(entry.totalIncorrect || '');
      row.push(entry.totalMarks || '');
      row.push(entry.totalMarksObtained || '');
      row.push(entry.submittedAt || '');
      row.push(entry.surveyId || '');
  
      // Push the row to excelData
      excelData.push(row);
    });
  
    // Logging the array to verify the structure
    console.log(excelData);
  
    // Create a new workbook and worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Survey Data');
  
    // Export the workbook as an Excel file
    XLSX.writeFile(workbook, 'survey_data.xlsx');
    toast.dismiss(toastId);
    toast.success("Successfully saved");
  }


  const fetchTotalResponse = async () => {
    const toastId = toast.loading("Loading all the responses Please wait")
    try {
      const response = await apiConnector(
        'GET',
        `${surveyEndpoints.EXPORT_RESPONSE}?surveyId=${id}`, // Adjust the endpoint and query params accordingly
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      createExcelFromData(response?.data?.data)
      toast.success("Responses Fetched Successfully!");
    } catch (error) {
      toast.error(` Error Occured ${error?.response?.data?.message}`)
      console.error('Error fetching users:', error);
    } finally {
      toast.dismiss(toastId)
    }
  };



  return (
    <Box width={'100%'}>
      <Button variant='outlined' sx={{marginBottom:'20px',marginLeft:'5%'}} color='primary' onClick={fetchTotalResponse}>Export Responses</Button>
      <TableContainer sx={{ width: '95%', marginX:'auto' }} component={Paper}>
        <Table aria-label="user table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  UserId
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  Submitted Time
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  Action
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row?.studentId?.name || row?.teacherId?.name || row?.councellerId?.name || 'No name available'}</TableCell>
                <TableCell>{row?.studentId?.userId || row?.teacherId?.userId || row?.councellerId?._id  || 'No UserId available'}</TableCell>
                <TableCell sx={{ fontFamily: 'roboto' }}>{row.submittedAt.split('.')[0].replace('T',' ')}</TableCell>
                <TableCell sx={{ ":hover": { color: 'red', cursor: 'pointer' } }} onClick={() => { navigate(`/survey-response/${row?._id}`) }}>
                  View
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Load More Button */}
      <Box mt={3} textAlign="center">
        <Button
          onClick={handleLoadMore}
          disabled={loading || page >= totalPages} // Disable if loading or no more pages
          variant="outlined"
          color="primary"
        >
          {loading ? 'Loading...' : 'Load More'}
        </Button>
      </Box>
    </Box>
  );
};

export default UserTable;
