import { toast } from 'react-hot-toast'
import React, { useState } from 'react';
import { Button, Box, Typography, Grid, Modal } from '@mui/material';
import CreateQuestionModal from './CreateQuestionModal';
import RenderQuestions from './RenderQuestion';
import AddIcon from '@mui/icons-material/Add';
import {
    TextField, FormControl, RadioGroup, FormControlLabel, Radio,
    Checkbox, MenuItem, Select, IconButton,
} from '@mui/material';
import { apiConnector } from '../../services/ApiConnector';
import { surveyEndpoints } from '../../services/ApiEndPoints';
import { useSelector } from 'react-redux';

import { Paper } from '@mui/material';


import SurveyResponseTable from './SurveyResponseTable';
import EditSurveyModal from './EditSurveyModal';

const SurveyDetail = ({setSurveyData, questionaireType, id, surveyData, questions, setQuestions }) => {
    const token = useSelector((state) => state.auth.token);
    const [open, setOpen] = useState(false); //open Question Adding form
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // what  question you are creating .
    const [questionType, setQuestionType] = useState('');
    const [openResponses, setOpenResponses] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const handleOpenModal = () => setOpenEditModal(true);
    const handleCloseModal = () => setOpenEditModal(false);



    const handleDeleteQuestion = async (index) => {
        if (window.confirm("Are you sure you want to delete the question?")) {
            const toastId = toast.loading("Deleting Question, Please Wait...");
            const questionId = questions[index]?._id;

            if (!questionId) {
                toast.error("Question ID is missing. Please refresh the page and try again.");
                return;
            }

            try {
                const response = await apiConnector(
                    "DELETE",
                    `${surveyEndpoints.DELETE_QUESTION}/${questionId}`,
                    {},
                    {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                );

                if (response?.data?.status) {
                    setQuestions((prevQuestions) =>
                        prevQuestions.filter((_, i) => i !== index)
                    );
                    toast.success("Question deleted successfully.");
                } else {
                    toast.error("Failed to delete the question. Please try again.");
                }

            } catch (error) {
                toast.error(`Failed to delete question: ${error.message}`);
            } finally {
                toast.dismiss(toastId);
            }
        }
    };
    const handleQuestionTypeChange = (event) => {
        console.log("hello ", event.target)
        setQuestionType(event.target.value);
        setOpen(true)
    };

    console.log(surveyData)
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                backgroundColor: '#F3F4F6',
                minHeight: '75vh',
                width: '94%',
                mx: 'auto',
                alignItems: 'center',
                padding: 2,
                overflowY: 'auto',
            }}
        >
            {/* Heading area */}
            <Grid container justifyContent="space-between" alignItems="center" sx={{ width: '95%', paddingX: '20px', paddingY: '14px', display: 'flex', boxShadow: '2px', backgroundColor: 'white', borderRadius: '20px' }}>



                <Paper elevation={3} sx={{ p: 3, mb: 3, width: '100%' }}>
                    <Grid container spacing={3} alignItems="center">
                        {/* Survey Name */}
                        <Grid display={'flex'} flexDirection={'row'} justifyContent={'center'} gap={2} alignItems={'center'} item xs={12}>
                            <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '30px' }} gutterBottom>
                                {surveyData?.survey?.name}
                            </Typography>
                            <Button onClick={() => {
                                setOpenEditModal(true)
                            }}>Edit</Button>
                        </Grid>

                        {/* Survey Description - Full Width */}
                        <Grid item xs={12}>
                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                {surveyData?.survey?.description}
                            </Typography>
                        </Grid>

                        {/* Two Columns - Course, Type */}
                        <Grid item xs={12} md={6}>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                <strong>Course:</strong> {surveyData?.survey?.courseId?.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                <strong>Type:</strong> {surveyData?.survey?.type}
                            </Typography>
                        </Grid>

                        {/* Two Columns - Number of Questions, Quiz Specific Fields */}
                        <Grid item xs={12} md={6}>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                <strong>Number of Questions:</strong> {surveyData?.questions?.length}
                            </Typography>
                        </Grid>

                        {questionaireType === 'quiz' && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body2" color="textSecondary" gutterBottom>
                                        <strong>Duration:</strong> {surveyData?.survey?.duration}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body2" color="textSecondary" gutterBottom>
                                        <strong>Passing Percentage:</strong> {surveyData?.survey?.passingMark}%
                                    </Typography>
                                </Grid>
                            </>
                        )}

                        {/* Start Date, End Date */}
                        <Grid item xs={12} md={6}>
                            <Typography variant="body2" color="textSecondary">
                                <strong>Start Date:</strong> {new Date(surveyData?.survey?.startDate).toLocaleDateString()}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body2" color="textSecondary">
                                <strong>End Date:</strong> {new Date(surveyData?.survey?.endDate).toLocaleDateString()}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>


                <FormControl>
                    <Select
                        sx={{
                            backgroundColor: '#EF492E',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#D14028', // Adjusted hover color
                                borderColor: '#D14028',
                            },
                            border: '1px solid white',
                        }}
                        size="small"
                        value={questionType}
                        onChange={handleQuestionTypeChange}
                        displayEmpty
                        renderValue={() => {
                            return questionType ? questionType : (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <AddIcon sx={{ marginRight: 1 }} />
                                    Add Question
                                </Box>
                            );
                        }}
                    >
                        <MenuItem disabled value="">
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <AddIcon sx={{ marginRight: 1 }} />
                                Add Question
                            </Box>
                        </MenuItem>
                        <MenuItem value="Single-choice">Single Choice Questions</MenuItem>
                        {
                            questionaireType != 'quiz' && <MenuItem value="Multiple-choice">Multiple Choice Questions</MenuItem>
                        }

                        {
                            questionaireType != 'quiz' && <MenuItem value="Rating">Rating out of 5</MenuItem>
                        }

                        {
                            questionaireType != 'quiz' && <MenuItem value="Agree-disagree">Agree/Disagree</MenuItem>
                        }

                        {
                            questionaireType != 'quiz' && <MenuItem value="Short-answer">Short Answer</MenuItem>
                        }

                        {
                            questionaireType != 'quiz' && <MenuItem value="Brief-answer">Brief Answer</MenuItem>
                        }
                    </Select>
                </FormControl>

                {/* Render question */}
                <Button onClick={() => { setOpenResponses(false) }}
                    sx={{
                        backgroundColor: openResponses ? 'white' : '#EF492E',
                        borderColor: openResponses ? '#EF492E' : 'white',
                        color: openResponses ? '#EF492E' : 'white',
                        '&:hover': {
                            color: 'white',
                            backgroundColor: '#EF492E', // Example hover color, adjust as needed
                        },
                        border: `1px solid ${openResponses ? '#EF492E' : 'white'}`,
                        paddingX: '30px', paddingY: '11px'
                    }}
                    size="small"
                    displayEmpty
                >
                    Show Question

                </Button>
                {/* Responses */}
                <Button onClick={() => { setOpenResponses(true) }}
                    sx={{
                        backgroundColor: !openResponses ? 'white' : '#EF492E',
                        borderColor: !openResponses ? '#EF492E' : 'white',
                        color: !openResponses ? '#EF492E' : 'white',
                        '&:hover': {
                            color: 'white',
                            backgroundColor: '#EF492E', // Example hover color, adjust as needed
                        },
                        border: `1px solid ${!openResponses ? '#EF492E' : 'white'}`,
                        paddingX: '30px', paddingY: '11px'
                    }}
                    size="small"
                    displayEmpty
                >
                    Show Responses

                </Button>
            </Grid>
            {/* Area with questions and Add Question button */}
            {
                openResponses ?
                    <SurveyResponseTable id={id} /> :
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            overflowY: 'auto',
                            minHeight: '65vh',
                            width: '100%'
                        }}
                    >
                        {
                            open && <CreateQuestionModal questionaireType={questionaireType} id={id} setQuestionType={setQuestionType} questionType={questionType} questions={questions} setQuestions={setQuestions} handleClose={handleClose} />
                        }
                        {/* Present the questions Here */}
                        <RenderQuestions questionaireType={questionaireType} setQuestions={setQuestions} questions={questions} handleDeleteQuestion={handleDeleteQuestion} />
                        {/* Footer Part */}
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={questions?.length != 0 ? 'space-between' : 'center'} width={'95%'}>
                            {
                                (!open && questions?.length == 0) && <Box sx={{ fontSize: '25px', marginTop: '200px' }}>Please Add A Question!</Box>
                            }

                        </Box>

                    </Box>
            }

            {/* Edit Survey Modal */}
            <EditSurveyModal
                setSurveyData={setSurveyData}
                isOpen={openEditModal}
                handleClose={handleCloseModal}
                isQuiz={questionaireType=='quiz'}  // Change to false if it's a survey
                initialData={surveyData?.survey}
                surveyData = {surveyData}
            />
        </Box>
    );
};

export default SurveyDetail;
