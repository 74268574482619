import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// import useFirebaseImageUpload from '../../hooks/useFirebaseImageUpload'
import useAwsFileUpload from '../../hooks/useAwsImageUpload'


import SchooleSelectorDropdown from "../../common/SchoolSelectorDropdown";
import CourseSelectedDropdown from "../../common/CourseSelectedDropdown";
import StudentSelectedDropdown from "../../common/StudentSelectedDropdown";
import TeacherSelectedDropdown from "../../common/TeacherSelectedDropdown";
import { apiConnector } from "../../services/ApiConnector";
import { reportEndpoints } from "../../services/ApiEndPoints";
import {toast} from "react-hot-toast"
import { useSelector } from "react-redux";
const statuses = ["Active", "Inactive"];
const languages = ["English", "Hindi"];



const ReportForm = () => {
  const {uploadFileToAws, uploading} = useAwsFileUpload();
  // const { uploadImageToFirebase } = useFirebaseImageUpload();  
  const token = useSelector((state)=>state.auth.token)
  const navigate = useNavigate();
  const [fileName, setFileName] = useState("");
  const [status, setStatus] = useState("");
  const [language, setLanguage] = useState("");
  const [school, setSchool] = useState(null);
  const [course, setCourse] = useState(null);
  const [teacher, setTeacher] = useState([]);
  const [student, setStudent] = useState(null);
  const [file, setFile] = useState(null);

  const handleSubmit = async(e) => {
    const toastId = toast.loading("Loading...");
    e.preventDefault();

    if(!file){ 
        toast.error("Please Select a Report File");
        return;
    }

    let teacherIdArr = teacher?.map((item)=>{
         return item._id;
    })

    try {
      let reportUrl = "";

      if (file) {
        // Use the hook to upload the image
        reportUrl = await uploadFileToAws(file);
        if (!reportUrl) {
          throw new Error("Failed to upload image");
        }
      }
      if(reportUrl == ""){
           toast.error("Please try again Report Url Not found");
           return;
      }
      const data = {
        courseId:course?._id||null,
        teacherId:teacherIdArr,
        studentId:student?._id,
        schoolId:school?._id,
        url:reportUrl,
        fileName,
        statusVal:(status=="ACTIVE"),
        language,
      };
      console.log(data);
      const response = await apiConnector(
        "POST",
        `${reportEndpoints.CREATE_REPORT}`,
        data,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
  
      // Dismiss the loading toast
      toast.dismiss(toastId);
  
      // Show success toast
      toast.success("Report Created Successfully!", {
        id: toastId, // You can reuse the same ID if needed, or remove this line
      });
  
      console.log("Report Created:", response.data);
      navigate('/reports')
    } catch (error) {
      // Dismiss the loading toast
      toast.dismiss(toastId);
  
      // Show error toast
      toast.error("Failed to create Report. Please try again.", {
        id: toastId, // You can reuse the same ID if needed, or remove this line
      });
  
      console.error("Error creating Report:", error);
    }
  };

  useEffect(() => {
    setTeacher([]);
    setStudent([]);
  }, [school])
  return (
    <Container component="form" onSubmit={handleSubmit} sx={{ margin: "0 auto", width: "80%", marginBottom: "30px" }}>
      <Typography sx={{ fontSize: "26px", textAlign: "center", my: "10px" }} fontWeight="bold" color="#EF492E">
        Add New Report
      </Typography>
     {/* //file */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputLabel required sx={{ fontWeight: "bold", fontSize: "14px" }}>File Name</InputLabel>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            required
            margin="normal"
          />
        </Grid>

        <SchooleSelectorDropdown size="small" school={school} setSchool={setSchool} />


        <CourseSelectedDropdown size="small" course={course} setCourse={setCourse} />



        <StudentSelectedDropdown size="small" student={student} setStudent={setStudent} school={school} />



        <TeacherSelectedDropdown size="small" teacher={teacher} setTeacher={setTeacher} school={school} />


        <Grid item xs={6}>
          <InputLabel sx={{ fontWeight: "bold", fontSize: "14px" }}>Status</InputLabel>
          <FormControl size="small" fullWidth>
            <Select value={status} onChange={(e) => setStatus(e.target.value)} >
              {statuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <InputLabel sx={{ fontWeight: "bold", fontSize: "14px" }}>Select Language</InputLabel>
          <FormControl size="small" fullWidth>
            <Select value={language} onChange={(e) => setLanguage(e.target.value)} >
              {languages.map((language) => (
                <MenuItem key={language} value={language}>
                  {language}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <InputLabel required sx={{ fontWeight: "bold", fontSize: "14px" }}>Select File</InputLabel>
          <TextField
            size="small"
            type="file"
            variant="outlined"
            required
            fullWidth
            inputProps={{ accept: "application/pdf" }} // Restrict to PDF files only
            onChange={(e) => setFile(e.target.files[0])}
            margin="normal"
          />
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" color="error" sx={{ mt: 2, backgroundColor: "#EF492E", width: "20%" }} type="submit">
            Add Report
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReportForm;
