import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { apiConnector } from "../../services/ApiConnector";
import { categoryEndpoints } from "../../services/ApiEndPoints";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const CategoryModal = ({ open, handleClose, categories,setCategories }) => {
  const token = useSelector((state)=>state.auth.token)
  const [parentCategory, setParentCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [status, setStatus] = useState("Active");

  const handleFormSubmit = async () => {
    // Display a loading toast
    const toastId = toast.loading("Submitting Category...");
  
    // Prepare the category data for submission
    const categoryData = {
      parentCategory,
      name:categoryName,
      status: status || "active", // Ensure status defaults to "active" if not set
    };

    try {
      // Make the API POST request to submit the category data
      const response = await apiConnector(
        "POST",
        categoryEndpoints.ADD_CATEGORY, // Replace with your actual endpoint
        categoryData,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Ensure you have the token for authorization
        }
      );

      console.log(response)
  
      // Handle success
      if (response.data.status) {
        toast.success("Category Created Successfully!");
        // Optionally, you can refresh the category list or handle the new data
        setCategories((prevCategories) => [...prevCategories, response.data.data]);
      } else {
        toast.error("Failed to Create Category!");
      }
    } catch (error) {
      toast.error("Category Submission Failed!");
      console.error("Error creating category:", error);
    } finally {
      toast.dismiss(toastId); // Dismiss the loading toast
      handleClose(); // Close the modal after submission
    }
  };
  

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2" mb={2}>
          Create Category
        </Typography>

        {/* Parent Category Dropdown */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Parent Category</InputLabel>
          <Select
            value={parentCategory}
            onChange={(e) => setParentCategory(e.target.value)}
            label="Parent Category"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {categories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Category Name Input */}
        <TextField
          fullWidth
          label="Category Name"
          variant="outlined"
          margin="normal"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
        />

        {/* Status Dropdown */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Status</InputLabel>
          <Select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            label="Status"
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </Select>
        </FormControl>

        {/* Buttons */}
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="contained" color="primary" onClick={handleFormSubmit}>
            Submit
          </Button>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CategoryModal