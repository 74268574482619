import React, { useEffect, useState } from "react";
import { AccordionSummary, Typography, Switch, Box, Tooltip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import toast from 'react-hot-toast';
import { apiConnector } from "../../../services/ApiConnector";
import { lectureEndpoints } from "../../../services/ApiEndPoints";
import { useSelector } from "react-redux";

const AccordionWithToggle = ({ expandedIndex, index, lecture }) => {
  const [status, setStatus] = useState("active");
  const token  = useSelector((state) => state.auth.token);

  useEffect(() => {
    setStatus(lecture?.status);
  }, [lecture]);

  const handleStatusChange = async (event) => {
    event.stopPropagation(); // Prevent accordion from expanding

    // Optimistically update the status
    const newStatus = event.target.checked ? "active" : "inactive";
    const previousStatus = status; // Store previous status to revert if API fails

    try {
      const response = await apiConnector(
        "PUT",
        `${lectureEndpoints.UPDATE_LECTURE}/${lecture?.id}`,
        { status: newStatus },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      if (response.data.status) {
        setStatus(newStatus);
        toast.success("Status updated successfully!");
      } else {
        throw new Error("Failed to update lecture status");
      }
    } catch (error) {
      // Revert status change if the API request fails
      setStatus(previousStatus);
      toast.error("Failed to update status");
    }
  };

  return (
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="subtitle1">
        {expandedIndex === index && (
          <span role="img" aria-label="playing">🎬</span>
        )}
        {lecture.title}
      </Typography>

      {/* Status and Toggle Button on the right side */}
      <Box sx={{ ml: "auto", display: "flex", alignItems: "center" }}>
        <Tooltip title={status === "active" ? "Active" : "Inactive"} placement="top" arrow>
          {/* Toggle Switch */}
          <Switch
            checked={status === "active"}
            onChange={handleStatusChange}
            onClick={(event) => event.stopPropagation()} // Prevent accordion expansion
            color="success"
            size="small"
          />
        </Tooltip>
      </Box>
    </AccordionSummary>
  );
};

export default AccordionWithToggle;
