import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Button,
  TextField,
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Select,
  MenuItem,
  Modal,
  Card,
  CardContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { toast } from 'react-hot-toast';
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { apiConnector } from "../../services/ApiConnector";
import { courseEndpoints } from "../../services/ApiEndPoints"; 
import CounsellorModal from "../../components/courses/course-resipants/CounsellorInsertModal"; // Assuming a similar modal for counsellors

const CourseCounsellor = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const { courseId } = useParams(); // Extract courseId from URL
  const [counsellorsPerPage, setCounsellorsPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [counsellorsData, setCounsellorsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 800); // Debouncing search input
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [courseName, setCourseName] = useState("");
  const [totalCounsellors, setTotalCounsellors] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedCounsellor, setSelectedCounsellor] = useState(null);
  const [isCardOpen, setIsCardOpen] = useState(false);

  const fetchCounsellors = async (currentPage = 1, searchQuery = "", reset = false) => {
    setLoading(true);
    const toastId = toast.loading("Fetching Enrolled Counsellors...");
    try {
      const response = await apiConnector(
        "GET",
        `${courseEndpoints.COURSE_COUNSELLORS}/${courseId}?page=${currentPage}&perPage=${counsellorsPerPage}&search=${searchQuery}`,
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      if (reset) {
        setCounsellorsData(response?.data?.enrolledCouncellers);
      } else {
        setCounsellorsData((prev) => [...prev, ...response?.data?.enrolledCouncellers]);
      }
      toast.success("Successfully fetched counsellors");
      setCourseName(response?.data.courseName || "Course");
      setTotalPages(response?.data.totalPages || 1);
      setTotalCounsellors(response?.data?.totalEnrolled || 0);
    } catch (error) {
      toast.error("Failed to fetch counsellors");
      console.error("Failed to fetch counsellors", error);
    } finally {
      setLoading(false);
      toast.dismiss(toastId);
    }
  };

  useEffect(() => {
    if (courseId) {
      fetchCounsellors(1, debouncedSearchTerm, true); // Reset and fetch new data
    }
  }, [courseId, counsellorsPerPage,  debouncedSearchTerm]);

  useEffect(() => {
    if (currentPage > 1) {
      fetchCounsellors(currentPage, debouncedSearchTerm); // Load more data when page changes
    }
  }, [currentPage]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to page 1 when search term changes
  };

  const handlePageSizeChange = (event) => {
    setCounsellorsPerPage(event.target.value);
    setCurrentPage(1); // Reset to page 1 when page size changes
  };

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleMoreClick = (counsellor) => {
    setSelectedCounsellor(counsellor);
    setIsCardOpen(true);
  };

  const EnrollInCourse = async (counsellorIds) => {
    const toastId = toast.loading("Enrolling...");
    try {
      const response = await apiConnector(
        "POST",
        `${courseEndpoints.COURSE_BULK_USER_INSERT}`,
        { userIds: counsellorIds, courseId, role: "counceller" },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      toast.success("Enrolled Successfully! Please refresh to see results");
      fetchCounsellors(1, "", true);
    } catch (error) {
      toast.error(`Failed to Enroll`);
      console.error(error);
    } finally {
      toast.dismiss(toastId);
    }
  };

  const handleRemoveFromCourse = async (counsellorId) => {
    const toastId = toast.loading("Removing counsellor...");
    try {
      const response = await apiConnector(
        "POST",
        `${courseEndpoints.COURSE_REMOVE_USER}`,
        { userId: counsellorId, courseId, role: "counceller" },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      setIsCardOpen(false);
      toast.success("Removed Successfully! Please refresh to see results");
      fetchCounsellors(1, "", true);
    } catch (error) {
      toast.error(`Failed to Remove`);
      console.error(error);
    } finally {
      toast.dismiss(toastId);
    }
  };

  const handleEnroll = (counsellors, setSelectedCounsellors) => {
    setIsModalOpen(false);
    setSelectedCounsellors([]);
    const counsellorIds = counsellors.map((counsellor) => counsellor._id);
    EnrollInCourse(counsellorIds);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  return (
    <Container sx={{ mt: 4 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Typography variant="h4">
          {courseName}
          <Typography component="span" color="textSecondary" sx={{ fontSize: "0.875rem" }}>
            ({totalCounsellors} Counsellors)
          </Typography>
        </Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#EF492E", color: "#fff", ml: 1, "&:hover": { backgroundColor: "#D44428" } }}
          startIcon={<AddIcon />}
          onClick={() => setIsModalOpen(true)}
        >
          Add New Counsellor
        </Button>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <TextField
          size="small"
          variant="outlined"
          placeholder="Search Counsellors"
          sx={{ width: "30%" }}
          value={searchTerm}
          onChange={handleSearchChange}
        />
       
      </Box>

      <TableContainer component={Paper} sx={{ borderRadius: "8px", border: "1px solid #f5a48b", marginTop: "10px", overflowX: "auto" }}>
        <Table sx={{ tableLayout: "auto" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
              <TableCell sx={{ fontWeight: "bold" }} width={"50px"}>S.No</TableCell>
              <TableCell sx={{ fontWeight: "bold", cursor: "pointer" }} width={"150px"}>Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} width={"250px"}>Email</TableCell>
              {/* <TableCell sx={{ fontWeight: "bold" }} width={"250px"}>UserId</TableCell> */}
              <TableCell sx={{ fontWeight: "bold" }} width={"100px"}>City</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} width={"50px"}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {counsellorsData.map((counsellor, index) => (
              <TableRow key={counsellor?._id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{counsellor?.name}</TableCell>
                <TableCell>{counsellor?.email}</TableCell>
                {/* <TableCell>{counsellor?.userId}</TableCell> */}
                <TableCell>{counsellor?.city}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleMoreClick(counsellor)}>
                    More
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        { (
          <Button sx={{mr:2}}  variant="outlined" onClick={handleLoadMore} disabled={loading}>
            Load More
          </Button>
        )}
        <FormControl>
          <Select value={counsellorsPerPage} onChange={handlePageSizeChange} displayEmpty size="small">
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Box>

      
        <CounsellorModal 
        open={isModalOpen} 
        // onClose={() => setIsModalOpen(false)}   
        onClose={handleCancel} 
        courseId={courseId} 
        onEnroll={handleEnroll} />
      

      <Modal open={isCardOpen} onClose={() => setIsCardOpen(false)}>
        <Card sx={{ width:'40%', padding: "10px", margin: "auto", marginTop: "150px" }}>
          <CardContent>
            <Typography variant="h6">{selectedCounsellor?.name}</Typography>
            <Typography variant="body2">Email: {selectedCounsellor?.email}</Typography>
            <Typography variant="body2">UserId: {selectedCounsellor?.userId}</Typography>
            <Typography variant="body2">City: {selectedCounsellor?.city}</Typography>
            <Button variant="outlined" color="error" sx={{ marginTop: "10px" }} onClick={() => handleRemoveFromCourse(selectedCounsellor?._id)}>
              Remove from Course
            </Button>
          </CardContent>
        </Card>
      </Modal>
    </Container>
  );
};

export default CourseCounsellor;
