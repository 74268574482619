import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

const NotAuthorized = () => {
  const navigate = useNavigate();
  
  return (
    <Box 
      display={'flex'} 
      flexDirection={'column'} 
      height={'80vh'} 
      width={'50%'} 
      mx={'auto'}
      justifyContent={'center'} 
      alignItems={'center'}
      textAlign={'center'}
      padding={3}
    >
      <Typography variant='h4' gutterBottom>
        You are not authorized to access this service!
      </Typography>
      <Typography variant='body1' fontWeight={'bold'} marginBottom={2}>
        It seems you don't have the necessary permissions to view this page. 
      </Typography>
      <Typography variant='body2' marginBottom={3}>
        Please contact the administrator if you believe this is a mistake, or if you require additional access. 
        The admin can review your permissions and grant you the necessary rights to use this service.
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => navigate('/')}
      >
        Go to Dashboard
      </Button>
    </Box>
  );
}

export default NotAuthorized;
