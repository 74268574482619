import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, TextField, Button, Checkbox, List, ListItem, ListItemText } from "@mui/material";
import { useDebounce } from 'use-debounce';
import { apiConnector } from "../../../services/ApiConnector";
import {  courseEndpoints } from "../../../services/ApiEndPoints"; // Update API endpoints for counselors
import { useSelector } from "react-redux";

const CounselorModal = ({ open, onClose, onEnroll, courseId }) => {
  const token = useSelector((state) => state.auth.token);
  const [counselors, setCounselors] = useState([]);
  const [selectedCounselors, setSelectedCounselors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 800); // Debounce for 800ms
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchCounselors = async (page = 1, query = "", reset = false) => {
    setLoading(true);
    try {
      const response = await apiConnector(
        "GET",
        `${courseEndpoints.COURSE_UNENROLLED_COUNSELLORS}/${courseId}?page=${page}&perPage=10&search=${query}`, // Adjust API endpoint
        null,
        { Authorization: `Bearer ${token}` }
      );
      
      if (reset) {
        setCounselors(response?.data?.unenrolledCouncellers); // Use counselors instead of students
      } else {
        setCounselors((prev) => [...prev, ...(response?.data.unenrolledCouncellers)]); // Append new counselors
      }

      setTotalPages(response?.data.totalPages || 1);
    } catch (error) {
      console.error("Failed to fetch counselors", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchCounselors(1, debouncedSearchTerm, true); // Fetch counselors on modal open
      setCurrentPage(1);
    }
  }, [open, debouncedSearchTerm]);

  useEffect(() => {
    if (currentPage > 1 && open) {
      fetchCounselors(currentPage, debouncedSearchTerm);
    }
  }, [currentPage]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleSelectCounselor = (counselor) => {
    setSelectedCounselors((prev) => {
      if (prev.includes(counselor._id)) {
        return prev.filter((id) => id !== counselor._id);
      } else {
        return [...prev, counselor._id];
      }
    });
  };

  console.log("hello moto!")
  const handleEnrollClick = () => {
    const selected = counselors.filter((counselor) =>
      selectedCounselors.includes(counselor._id)
    );
    onEnroll(selected, setSelectedCounselors);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 500,
          height: "80vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          p: 3,
          borderRadius: 1,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Select Counselors
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search counselors"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ mb: 2 }}
        />
        <List>
          {counselors?.map((counselor, index) => (
            <ListItem
              key={index + 1}
              button
              onClick={() => handleSelectCounselor(counselor)}
            >
              <Checkbox checked={selectedCounselors.includes(counselor._id)} />
              <ListItemText primary={counselor.name} />
              <ListItemText sx={{ textAlign: 'end' }} primary={counselor.userId} />
            </ListItem>
          ))}
        </List>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Button variant="outlined" onClick={handleEnrollClick}>
            Enroll
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          {currentPage < totalPages && (
            loading ? (
              <Button variant="text" disabled>
                Loading...
              </Button>
            ) : (
              <Button variant="text" onClick={handleLoadMore}>
                Load More
              </Button>
            )
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CounselorModal;
