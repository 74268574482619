import React, { useState, useEffect } from 'react';
import { Grid, FormControl, TextField, Autocomplete, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { apiConnector } from '../../services/ApiConnector';
import { categoryEndpoints } from '../../services/ApiEndPoints'; // Adjust this import as needed

const CategorySelector = ({required, disabled,setSubCategory, size, category, setCategory, parentCategory ,label }) => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.token);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await apiConnector(
        'GET', // Changed to POST
        `${categoryEndpoints.GET_CATEGORY_VIA_PARENT}?parentId=${parentCategory?._id || null}`, 
         null, 
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      
      setCategoryOptions(response.data.data); // Adjust response mapping if needed
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch categories when the component mounts or when parentCategory changes
  useEffect(() => {
      fetchCategories();
  }, [parentCategory]);


  return (
    <Grid item xs={12} mt={2} sm={6}>
      <FormControl fullWidth variant="outlined">
        <Autocomplete
          size={size || ''}
          options={categoryOptions}
          required={required}
          disabled={disabled}
          getOptionLabel={(option) => option.name || ''} // Adjust the label field based on API data
          loading={loading}
          value={category}
          onChange={(event, newValue) => {
           if(setSubCategory)setSubCategory(null);
            setCategory(newValue)
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              label={label}
              variant="outlined"
              required={required}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </Grid>
  );
};

export default CategorySelector;
