import { toast } from "react-hot-toast";
import { apiConnector } from '../ApiConnector';
import { staffEndpoints } from '../ApiEndPoints'; // Import your API endpoints for staff
import { setStaff, appendStaff, setLoading, clearStaff, setSearchTerm } from '../../Store/Slices/staffSlice'; // Adjust import path as necessary

export function fetchStaff(token, page, limit, searchTerm, reset = false) {
  return async (dispatch, getState) => {
    const { searchTerm: previousSearchTerm } = getState().staff;

    if (reset || searchTerm !== previousSearchTerm) {
      dispatch(clearStaff());
      dispatch(setSearchTerm(searchTerm));
    }

    const toastId = toast.loading("Loading staff data...");
    dispatch(setLoading(true));

    try {
      const response = await apiConnector(
        "GET",
        `${staffEndpoints.GET_ALL_STAFF}?page=${page}&perPage=${limit}&search=${searchTerm}`,
        null,
        { Authorization: `Bearer ${token}` }
      );
      if (response.status !== 200) {
        throw new Error("Could not fetch staff data");
      }

      const result = response?.data?.data;
      const totalStaff = response?.data?.totalSubAdmins;
      const totalPages = response?.data?.totalPages;

      
      if (reset || searchTerm !== previousSearchTerm || page === 1) {
        dispatch(setStaff({ staff: result, totalStaff, totalPages, currentPage: page, searchTerm }));
      } else {
        dispatch(appendStaff({ staff: result, totalStaff, totalPages, currentPage: page, searchTerm }));
      }

      toast.success("Staff data fetched successfully");
    } catch (error) {
      toast.error(`Error! ${error?.response?.data?.message}`);
    } finally {
      dispatch(setLoading(false));
      toast.dismiss(toastId);
    }
  };
}
