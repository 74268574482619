import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-hot-toast';
import { apiConnector } from '../../services/ApiConnector';
import { surveyEndpoints } from '../../services/ApiEndPoints';
import { useSelector } from 'react-redux';

// Modal Style
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
  overflowY: 'auto',
};

const EditSurveyModal = ({setSurveyData, isOpen, handleClose, isQuiz, initialData ,surveyData }) => {
  const token = useSelector((state) => state.auth.token);

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    passingMark: '',
    duration: null, // Initialize as null for number
    startDate: '',
    endDate: '',
    Instruction: '',
  });

  useEffect(() => {
    // Initialize form values when initialData or isQuiz changes
    if (initialData) {
      setFormData({
        name: initialData?.name || '',
        description: initialData?.description || '',
        passingMark: initialData?.passingMark || '',
        duration: initialData?.duration ?? null, // Use null or fallback value
        startDate: initialData?.startDate || '',
        endDate: initialData?.endDate || '',
        Instruction: initialData?.Instruction || '',
      });
    }
  }, [initialData, isQuiz]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name,value)
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === 'duration' || name === 'passingMark' ? +value : value, // Ensure numeric values are casted correctly
    }));
  };

  const handleQuillChange = (value) => {
    setFormData((prevState) => ({ ...prevState, Instruction: value }));
  };

  const handleSubmit = async () => {
    // Close the modal before starting the update process
    handleClose();

    // Show a loading toast while the form is being submitted
    const toastId = toast.loading(isQuiz ? 'Updating Quiz...' : 'Updating Survey...');
    
    try {
      // Send the updated data to the API
      const response = await apiConnector(
        'PUT',
        `${surveyEndpoints.UPDATE_SURVEY}/${initialData._id}`, // Assuming initialData contains the survey/quiz id
        formData,
        { Authorization: `Bearer ${token}` } // Assuming token is available in your context or passed as prop
      );

      

      if (response.status !== 200) {
        throw new Error('Could not update survey/quiz');
      }
       
      // Success message after updating
      setSurveyData({
          questions:surveyData?.questions , survey:response?.data?.data
      })
      toast.success(isQuiz ? 'Quiz updated successfully' : 'Survey updated successfully');

      return response.data.data;
    } catch (error) {
      // Display an error message if something goes wrong
      toast.error(`Error! ${error?.response?.data?.message || 'Something went wrong'}`);
      console.error('UPDATE SURVEY/QUIZ ERROR:', error);
    } finally {
      // Dismiss the loading toast once the operation is done
      toast.dismiss(toastId);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="edit-survey-modal-title"
      aria-describedby="edit-survey-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2" gutterBottom>
          {isQuiz ? 'Edit Quiz' : 'Edit Survey'}
        </Typography>

        {/* Name Field */}
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />

        {/* Description Field */}
        <TextField
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />

        {/* Passing Percentage (Only for Quiz) */}
        {isQuiz && (
          <TextField
            label="Passing Percentage (%)"
            name="passingMark"
            value={formData.passingMark}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
        )}

        {/* Duration (Only for Quiz) */}
        {isQuiz && (
          <TextField
            label="Duration (in minutes)"
            name="duration"
            value={formData.duration ?? ''} // Handle null state
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
        )}

        {/* Start Date */}
        <TextField
          label="Start Date"
          name="startDate"
          type="date"
          value={formData.startDate}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />

        {/* End Date */}
        <TextField
          label="End Date"
          name="endDate"
          type="date"
          value={formData.endDate}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />

        {/* Instructions (React Quill) */}
        <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
          Instruction
        </Typography>
        <ReactQuill
          theme="snow"
          value={formData.Instruction}
          onChange={handleQuillChange}
          style={{ height: '150px', marginBottom: '20px' }}
        />

        {/* Submit Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ mt: 4, width: '100%', fontWeight: 'bold' }}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default EditSurveyModal;
