import React from 'react';
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CategoryDetailModal = ({ open, handleClose,category }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="category-detail-modal-title"
      aria-describedby="category-detail-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography id="category-detail-modal-title" variant="h6" component="h2">
            Category Details
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography id="category-detail-modal-description" sx={{ mt: 2 }}>
          <strong>Category Name:</strong> {category?.name || 'N/A'}
        </Typography>
        
        <Typography sx={{ mt: 1 }}>
          <strong>Parent Category:</strong> {category?.parentCategory?.name || 'N/A'}
        </Typography>

        <Typography sx={{ mt: 2 }}>
          <strong>Child Categories:</strong>
        </Typography>
        <Box display={'flex'} mt={'1px'} flexDirection={'row'} gap={'10px'} flexWrap={'wrap'}>
          {category?.subcategories?.length ? (
            category.subcategories.map((child, index) => (
              <Typography sx={{border:'1px solid gray',px:'4px',borderRadius:'5px'}} key={index}>
                <ListItemText primary={child.name} />
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">No child categories available.</Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CategoryDetailModal;
