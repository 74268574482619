import React, { useState } from 'react'; 
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton
} from '@mui/material';
import { AttachFile as FileIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {toast} from 'react-hot-toast'

const FileSelector = ({ onFileSelect }) => {
  const [fileInputs, setFileInputs] = useState([]);

  const handleFileChange = (event) => {
    const pdfFiles = Array.from(event.target.files)
      .filter(file => file.type === 'application/pdf')
    setFileInputs(pdfFiles);
  };

  const handleRemoveFile = (fileName) => {
    setFileInputs(fileInputs.filter(file => file.name !== fileName.name));
  };

  const handleSubmit = () => {
    if(fileInputs.length > 0)onFileSelect(fileInputs);
    else{
       toast.error("Please Select atleast 1 pdf")
    }
  };

  return (
    <Box sx={{ p: 3, border: '1px solid #ccc', borderRadius: '8px', boxShadow: 2 }}>
      <Box>
        <label htmlFor="file-upload">
          <input
            id="file-upload"
            type="file"
            multiple
            accept="application/pdf"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <Button
            variant="outlined"
            component="span"
            sx={{
              mb: 2,
              bgcolor: 'primary.main',
              color: 'white',
              '&:hover': { bgcolor: 'primary.dark' },
              display: 'flex',
              alignItems: 'center',
              padding: 1,
              borderRadius: 4,
              border: '2px dashed white',
            }}
          >
            <FileIcon sx={{ mr: 1 }} />
            <Typography variant="body1" sx={{ height: '100px', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {fileInputs.length > 0 ? "Click Open To Start Mapping" : "Click to Choose PDF Files"} 
            </Typography>
          </Button>
        </label>
      </Box>

      <Button variant="contained" onClick={handleSubmit} sx={{ mt: 2 }}>
        Open
      </Button>

      {fileInputs.length > 0 && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">Selected Files:</Typography>
          <List>
            {fileInputs.map((file, index) => (
              <ListItem key={index} secondaryAction={
                <IconButton edge="end" onClick={() => handleRemoveFile(file)} sx={{ color: 'error.main' }}>
                  <DeleteIcon />
                </IconButton>
              }>
                <ListItemText sx={{ fontSize: '12px' }} primary={file?.name} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default FileSelector;
