import React, { useState, useEffect } from 'react';
import { TextField, Button, Paper, Box, Typography, Grid, FormControl, MenuItem, Select, InputLabel, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CourseSelectedDropDown from '../../common/CourseSelectedDropdown';
import { apiConnector } from '../../services/ApiConnector';
import { certificationEndpoints, notificationEndpoints } from '../../services/ApiEndPoints';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
// import useFirebaseImageUpload from '../../hooks/useFirebaseImageUpload';
import useAwsFileUpload from '../../hooks/useAwsImageUpload'
const AddCertification = () => {
  const {uploadFileToAws, uploading} = useAwsFileUpload();
  // const { uploadImageToFirebase, uploading } = useFirebaseImageUpload();
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');  // New state for description
  const [course, setCourse] = useState(null);
  const [file, setFile] = useState(null);
  const [teacherList, setTeacherList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [counsellorList,setCounsellorList] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [selectedCounsellor,setSelectedCounsellor] = useState('');
  const [selectedStudent, setSelectedStudent] = useState('');
  const [grade, setGrade] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch student and teacher lists based on the selected course
  useEffect(() => {
    if (course) {
      setSelectedStudent('');
      setSelectedTeacher('');
      setSelectedCounsellor('');
      fetchStudentAndTeacherCounsellorList(course?._id);
    }
  }, [course]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
    } else {
      toast.error('Only PDF files are allowed');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (title === "") {
      toast.error("Title is required");
      return;
    }

    if (!description) {
      toast.error("Description is required");
      return;
    }

    if (!file) {
      toast.error("Please upload a PDF file");
      return;
    }

    if (!course) {
      toast.error("Please select a course before submitting.");
      return;
    }

    if (!selectedTeacher && !selectedStudent && !selectedCounsellor) {
      toast.error("Please select either a teacher or a student or a Counsellor");
      return;
    }

    const toastId = toast.loading("Submitting certification...");
    try {
      let imageUrl = "";
      // If a file is provided, upload the image using the Firebase upload hook
      if (file) {
        imageUrl = await uploadFileToAws(file);
        if (!imageUrl) {
          toast.error("Error in uploading certificate")
          return;
        }
      }
      const data = {
        title,
        description,
        docLink:imageUrl,
        teacherId: selectedTeacher,
        studentId: selectedStudent,
        councellerId:selectedCounsellor,
        courseId: course._id,
        grade
      }
      console.log(data)

      const response = await apiConnector(
        "POST",
        certificationEndpoints.CREATE_CERTIFICATION,
        data,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Certification added successfully!");
      } else {
        toast.error("Failed to add certification. Please try again.");
      }
    } catch (error) {
      console.error("ADD CERTIFICATION ERROR:", error);
      toast.error(`Failed to add certification: ${error.message}`);
    } finally {
      toast.dismiss(toastId);
      navigate('/certifications');
    }
  };

  // Notification se uthaya hai 
  const fetchStudentAndTeacherCounsellorList = async (courseId) => {
    setLoading(true);
    try {
      const response = await apiConnector(
        "GET",
        `${notificationEndpoints.GET_USERS_BY_COURSEID}?courseId=${courseId}`,
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      const { students, teachers, counceller} = response?.data?.data || {};
      setStudentList(students);
      setTeacherList(teachers);
      setCounsellorList(counceller);
    } catch (error) {
      console.error("FETCH STUDENT AND TEACHER LIST ERROR:", error);
    } finally {
      setLoading(false);
    }
  };

  // Reset the selection of student when teacher is selected, and vice versa
  const handleTeacherChange = (e) => {
    setSelectedTeacher(e.target.value);
    if (e.target.value) {
      setSelectedStudent('');  // Reset student selection if teacher is selected
      setSelectedCounsellor('');
    }
  };

  const handleCounsellorChange = (e) => {
    setSelectedCounsellor(e.target.value);
    if (e.target.value) {
      setSelectedStudent('');  // Reset student selection if teacher is selected
      setSelectedTeacher('');
    }
  };

  const handleStudentChange = (e) => {
    setSelectedStudent(e.target.value);
    if (e.target.value) {
      setSelectedCounsellor('');
      setSelectedTeacher('');  // Reset teacher selection if student is selected
    }
  };

  return (
    <Paper sx={{ width: "80%", margin: "auto", padding: 4 }}>
      <Typography variant="h5" textAlign={'center'} fontWeight="bold" mb={3}>
        Add Certification
      </Typography>

      <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off" sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <TextField
          size='small'
          label="Title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter certification title"
        />

        {/* Description Field */}
        <TextField
          size='small'
          label="Description"
          variant="outlined"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter a description"
          multiline
          rows={4}  // Allowing multiline input for description
        />

        <Grid container spacing={4} >
          <CourseSelectedDropDown size={'small'} course={course} setCourse={setCourse} />

          {/* Student Dropdown */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth disabled={!course}>
              <InputLabel>Student {loading && <CircularProgress size={10}/>}</InputLabel>
              <Select
                size='small'
                value={selectedStudent}
                onChange={handleStudentChange}
              >
                <MenuItem value="">None</MenuItem> {/* None option */}
                {studentList?.map((student) => (
                  <MenuItem key={student._id} value={student._id}>
                    {student.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Teacher Dropdown */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth disabled={!course}>
              <InputLabel>Teacher {loading && <CircularProgress size={10} />}</InputLabel>
              <Select
                size='small'
                value={selectedTeacher}
                onChange={handleTeacherChange}
              >
                <MenuItem value="">None</MenuItem> {/* None option */}
                {teacherList?.map((teacher) => (
                  <MenuItem key={teacher._id} value={teacher._id}>
                    {teacher.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Counsellor Dropdown */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth disabled={!course}>
              <InputLabel>Counsellor {loading && <CircularProgress size={10} />}</InputLabel>
              <Select
                size='small'
                value={selectedCounsellor}
                onChange={handleCounsellorChange}
              >
                <MenuItem value="">None</MenuItem> {/* None option */}
                {counsellorList?.map((counsellor) => (
                  <MenuItem key={counsellor?._id} value={counsellor?._id}>
                    {counsellor?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
             {/* Grade */}
          <Grid item xs={12} sm={6}>
            <TextField
              size='small'
              fullWidth
              label="Grade"
              variant="outlined"
              value={grade}
              onChange={(e) => setGrade(e.target.value)}
              placeholder="Enter Grade"
            />
          </Grid>

          {/* File Upload */}
          <Grid item xs={12} sm={6}>
            <label htmlFor="upload-file">
              <input
                id="upload-file"
                type="file"
                accept="application/pdf"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <Button
                color="primary"
                variant="contained"
                component="span"
                fullWidth
              >
                {file ? file.name : 'Choose File'}
              </Button>
              {
                !file && <Typography variant="caption" display="block" color="textSecondary">
                Only PDF files are allowed.
              </Typography>
              }
              
            </label>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="space-between" mt={4}>
          <Button
            variant="outlined"
            onClick={() => { navigate("/certifications") }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="warning"
            type='submit'
            sx={{ backgroundColor: "#EF492E", "&:hover": { backgroundColor: "#d14026" } }}
          >
            Add Certification
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default AddCertification;
