import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, FormControl, InputLabel, Select, MenuItem, ListSubheader, Box } from '@mui/material';
import axios from 'axios';
import SchoolSelectorDropdown from '../../../common/SchoolSelectorDropdown'
import BrandSelectorDropdown from '../../../common/BrandSelectorDropdown';
import CourseSelectorDropdown from '../../../common/CourseSelectedDropdown';
import CounselorSelector from '../../../common/CounsellorDropdown';
import { apiConnector } from '../../../services/ApiConnector';
import { counselorEndpoints, reportEndpoints } from '../../../services/ApiEndPoints';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast'
import { PageviewRounded } from '@mui/icons-material';


const FilterCard = ({ users,
    setUsers, role, setRole, school, setSchool, brand, setBrand, course, setCourse, counselor, setCounselor, page, limit, setPage
}) => {
    const token = useSelector((state) => state.auth.token)
    // Fetch data based on the selected role
    const fetchDataByRole = async () => {
        const toastId = toast.loading("Loading Filtered Students")
        try {
            const response = await apiConnector(
                'POST',
                reportEndpoints.GET_FILTER_STUDENT,
                {
                    role,
                    school: school?._id || null,
                    counceller: counselor?._id || null,
                    brand: brand?._id || null,
                    course: course?._id || null,
                    page,
                    limit,
                },
                {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            );

            if (response.status !== 200) {
                toast.error("Error in fetching filtered users");
                return;
            }

            const newData = response?.data?.data || [];
            setUsers((prevUsers) => (prevUsers?.length > 0 ? [...prevUsers, ...newData] : newData));
            toast.success("data fetched!")
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error("An error occurred while fetching data.");
        } finally {
            toast.dismiss(toastId)
        }
    };


    // Trigger fetch on role change
    useEffect(() => {
        setUsers(null);
        setPage(1);
        fetchDataByRole();
    }, [role, school, counselor, brand, course]);

    useEffect(() => {
        if (page > 1) fetchDataByRole();
    }, [page]);


    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>Filter Options</Typography>

                {/* Role Filter */}
                <Box display={'flex'} flexDirection={'row'} gap={'10px'} flexWrap={'wrap'}>
                    <FormControl sx={{ width: '20%', mt: "-1px" }} size="small" margin="normal">
                        <InputLabel>Role</InputLabel>
                        <Select value={role} onChange={(e) => setRole(e.target.value)} label="Role">
                            <MenuItem value="student">Student</MenuItem>
                            <MenuItem value="teacher">Teacher</MenuItem>
                            <MenuItem value="counceller">Counsellor</MenuItem>
                        </Select>
                    </FormControl>

                    <Box sx={{ width: '18%' }}>
                        <SchoolSelectorDropdown required={false} size={'small'} school={school} setSchool={setSchool} />
                    </Box>
                    <Box sx={{ width: '18%' }}>
                        <BrandSelectorDropdown required={false} size={'small'} brand={brand} setBrand={setBrand} />
                    </Box>
                    <Box sx={{ width: '18%' }}>
                        <CourseSelectorDropdown required={false} size={'small'} course={course} setCourse={setCourse} />
                    </Box>
                    <Box sx={{ width: '18%' }}>
                        <CounselorSelector required={false} size={'small'} counselor={counselor} setCounselor={setCounselor} />
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default FilterCard;
