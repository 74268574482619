import React, { useState, useEffect } from "react";
import {
    Modal,
    Box,
    TextField,
    Button,
    Typography,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import React-Quill styles
import useAwsFileUpload from '../../hooks/useAwsImageUpload'
import toast from "react-hot-toast";
import { apiConnector } from "../../services/ApiConnector";
import { courseEndpoints } from "../../services/ApiEndPoints";
import { useSelector } from "react-redux";
import CategorySelectionModal from "../../common/category/CategorySelectionModal";


const style = {
    position: "absolute",
    overflowY: 'scroll',
    height: '90vh',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    border: "2px solid gray",
    boxShadow: 24,
    p: 4,
};

const CourseEditModal = ({ courseEditOpen, setCourseEditOpen, courseData, setCourseData }) => {
    const token = useSelector((state) => state.auth.token)
    const { uploadFileToAws } = useAwsFileUpload();
    const [courseName, setCourseName] = useState("");
    const [courseDescription, setCourseDescription] = useState("");
    const [courseImage, setCourseImage] = useState(null);
    const [courseCategory,setCourseCategory] = useState(null);
    const [courseSubCategory,setCourseSubCategory] = useState(null);
    const [imagePreview, setImagePreview] = useState("");

    console.log(courseCategory,courseSubCategory)

    useEffect(() => {
        if (courseData) {
            // Populate fields with the courseData when modal opens
            setCourseCategory(courseData?.category);
            setCourseSubCategory(courseData?.subCategory);
            setCourseName(courseData.name);
            setCourseDescription(courseData.description);
            setImagePreview(courseData.image); // Display current image URL from props
        }
    }, [courseData]);

    // Handle image file change
    const handleImageChange = (e) => {
        setCourseImage(e.target.files[0]);
        if(e.target.files[0])setImagePreview(URL.createObjectURL(e.target.files[0])); // Show the new image preview
    };

    // Handle the API call when updating course details
    const handleUpdate = async () => {
        if (courseName.trim() === "") {
            toast.error("Course Name can't be empty!");
            return;
        }
        const toastId = toast.loading("Updating course Details")

        const data = {
            name: courseName,
            description: courseDescription,
            category:courseCategory?._id || null,
            subCategory:courseSubCategory?._id || null,
        };

        if (courseImage) {
            const imageUrl = await uploadFileToAws(courseImage);
            if (!imageUrl) {
                toast.error("Failed to upload image");
                return; // Stop execution if upload fails
            }
            data.image = imageUrl;
        }

        try {
            const response = await apiConnector(
                "PUT",
                `${courseEndpoints.UPDATE_COURSE_DETAIL}/${courseData?.id}`,
                data,
                {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }
            );
            console.log(response)
            if (response?.data?.status) {
                setCourseData(response?.data?.data);
                handleClose(); // Close modal after successful update
                toast.success("Course updated successfully");
            } else {
                toast.error("Failed to update course");
            }
        } catch (error) {
            console.error("Error updating course:", error);
            toast.error("An error occurred while updating the course.");
        } finally {
            toast.dismiss(toastId)
        }
    };

    const handleClose = () => {
        // Reset the form fields
        setCourseName(courseData?.name);
        setCourseDescription(courseData?.description);
        setCourseImage(null);
        setImagePreview(courseData?.image);
        setCourseEditOpen(false);
        setCourseCategory(courseData?.courseCategory);
        setCourseSubCategory(courseData?.courseSubCategory)
    };


    return (
        <Modal open={courseEditOpen} onClose={handleClose}>
            <Box sx={style}>
                <Typography variant="h6" component="h2">
                    Edit Course
                </Typography>

                {/* Course Name */}
                <TextField
                    fullWidth
                    label="Course Name"
                    variant="outlined"
                    margin="normal"
                    value={courseName}
                    onChange={(e) => setCourseName(e.target.value)}
                />

                {/* Course Description using React-Quill */}
                <Typography variant="body1" sx={{ mt: 2 }}>
                    Course Description:
                </Typography>
                <ReactQuill
                    theme="snow"
                    value={courseDescription}
                    onChange={setCourseDescription}
                    style={{ height: "150px", marginBottom: "20px" }}
                />

                {/* Image Preview */}
                {imagePreview && (
                    <Box sx={{ mt: 7 }}>
                        <Typography variant="body2">Current Image:</Typography>
                        <img
                            src={imagePreview}
                            alt="Course Preview"
                            style={{ maxWidth: "200px", height: "auto" }}
                        />
                    </Box>
                )}

                {/* Image Upload */}
                <Button
                    variant="contained"
                    component="label"
                    sx={{ mt: 2, mb: 2 }}
                >
                    Upload Course Image
                    <input
                        type="file"
                        hidden
                        accept="image/*" // This attribute restricts file selection to images only
                        onChange={handleImageChange}
                    />
                </Button>
                

                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                {/* Category */}
                <Box sx={{width:'46%'}}>
                <CategorySelectionModal disabled={false} setSubCategory={setCourseSubCategory}  category={courseCategory} setCategory={setCourseCategory} label={"Category"}/>
                </Box>
                {/* SubCategory */}
                <Box sx={{width:'46%'}}>
                <CategorySelectionModal disabled={!courseCategory}  category={courseSubCategory} setCategory={setCourseSubCategory} parentCategory={courseCategory} label={"SubCategory"}/>
                </Box>
                </Box>
                {/* Action Buttons */}
                <Box display="flex" justifyContent="space-between" mt={3}>
                    <Button variant="contained" color="primary" onClick={handleUpdate}>
                        Update
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CourseEditModal;
