import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    staff: [],                // Array to hold staff data
    loading: false,           // Loading state
    totalStaff: 0,            // Total number of staff members
    totalPages: 0,            // Total number of pages
    currentPage: 1,           // Current page number
    searchTerm: '',           // Search term for filtering staff
};

const staffSlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        setStaff(state, action) {
            state.staff = action.payload.staff;
            state.totalStaff = action.payload.totalStaff;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        appendStaff(state, action) {
            state.staff = [...state.staff, ...action.payload.staff];
            state.totalStaff = action.payload.totalStaff;
            state.totalPages = action.payload.totalPages;
            state.currentPage = action.payload.currentPage;
            state.loading = false;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        clearStaff(state) {
            state.staff = [];
            state.totalStaff = 0;
            state.totalPages = 0;
            state.currentPage = 1;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        updateCurrentPage(state, action) {
            state.currentPage = action.payload;
        }
    },
});

export const { 
    setStaff, 
    appendStaff, 
    setLoading, 
    clearStaff, 
    setSearchTerm, 
    updateCurrentPage 
} = staffSlice.actions;

export default staffSlice.reducer;
