import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
} from "@mui/material";
import {
  Home as HomeIcon,
  School as SchoolIcon,
  People as PeopleIcon,
  GroupAdd as GroupAddIcon,
  Notifications as NotificationsIcon,
  ExitToApp as ExitToAppIcon,
  Quiz as QuizIcon,
  LibraryBooks as LibraryBooksIcon,
} from "@mui/icons-material";
import FitbitIcon from '@mui/icons-material/Fitbit';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import Logo from "../data/Logo.png";
import { useNavigate } from "react-router-dom";

import { useDispatch,useSelector } from 'react-redux'
import { logout } from "../services/Operations/AuthOperations";
import { useEffect } from "react";


const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileState = useSelector((state) => state.profile);
  const [accessFunctionality,setAccessFunctionalities]=useState([])
  const user = profileState.user;
  console.log(user)

  useEffect(() => {
    if (user) {
      setAccessFunctionalities(user?.permission || [])
    }
  }, [user]); 


  console.log(accessFunctionality)
  const [activeElem, setActiveElem] = useState('/')
  const menuItems = [
    { text: "Dashboard", icon: <HomeIcon />, path: '/',permission:null },
    { text: "Courses", icon: <LibraryBooksIcon />, path: '/courses',permission: "course-management" },
    { text: "All Assessments", icon: <SchoolIcon />, path: '/surveys',permission: "assessment-management" },
    { text: "Students", icon: <PeopleIcon />, path: '/students',permission:"student-management" },
    { text: "Teachers", icon: <GroupAddIcon />, path: '/teachers',permission:"teacher-management" },
    { text: "Brands", icon: <FitbitIcon />, path: '/brands',permission:"brand-management" },
    { text: "counsellor", icon: <AccessibilityNewIcon />, path: '/counselors',permission:"counsellor-management" },
    { text: "Schools", icon: <SchoolIcon />, path: '/schools',permission:"school-management" },
    { text: "Reports", icon: <QuizIcon />, path: '/reports',permission:"report-management" },
    { text: "Certificates", icon: <WorkspacePremiumIcon />, path: '/certifications',permission:"certificate-management" },
    { text: "Manage-Staff", icon: <PeopleIcon />, path: '/all-staff',permission:"No-Access" },
    { text: "Notifications", icon: <NotificationsIcon />, path: '/notifications',permission:"notification-management" },
  ];

  // Filter menu items based on user permissions
  const filteredMenuItems = menuItems?.filter(item => {
    if (user?.role === 'admin') return true;
    return item?.permission === null || accessFunctionality?.includes(item?.permission);
  });

  console.log(filteredMenuItems)

  const handleSignOut = async () => {
    if (window.confirm("Are you Sure you want to Log Out?")) {
      dispatch(logout(navigate));
    }
  }

  return (
    <Drawer
  variant="permanent"
  anchor="left"
  sx={{
    width: 240,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 240,
      backgroundColor: "#1D2026",
      color: "#fff",
      overflowY: "auto", // Ensure vertical scrolling
      "&::-webkit-scrollbar": {
        width: "4px", // Thinner scrollbar
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(255, 255, 255, 0.3)", // Light color for scrollbar
        borderRadius: "10px", // Rounded scrollbar edges
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.6)", // Darker color on hover
      },
    },
  }}
>
  <Box display="flex" flexDirection="column" height="100%">
    <Box display="flex" justifyContent="center" alignItems="center">
      <img
        src={Logo}
        alt="Company Logo"
        style={{ maxWidth: "100%", height: "50px", marginTop: "2px" }}
      />
    </Box>
    <Divider />
    <List>
      {filteredMenuItems?.map((item, index) => (
        <ListItemButton
          key={index}
          onClick={() => {
            navigate(item.path);
            setActiveElem(item.path);
          }}
          sx={{
            backgroundColor: activeElem === item.path ? "#FF6636" : "transparent", // Background for active state
            "&:hover": {
              backgroundColor: activeElem === item.path ? "#FF6636" : "#FF6636", // Background on hover
              color: "#fff", // Text color on hover
            },
          }}
        >
          <ListItemIcon sx={{ color: "inherit", fontSize: "14px" }}>
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItemButton>
      ))}
      {/* Signout button */}
      <ListItemButton
        onClick={handleSignOut}
        sx={{
          font: "bold",
          backgroundColor: "white",
          color: "#EF492E",
          "&:hover": {
            backgroundColor: "#FF6636",
            color: "#fff",
          },
        }}
      >
        <ListItemIcon sx={{ color: "inherit" }}>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary={"Sign Out"} />
      </ListItemButton>
    </List>
  </Box>
</Drawer>
  );
};

export default Sidebar;


