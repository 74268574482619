
import React, { useState } from 'react';
import { Container, Typography } from '@mui/material';
import FileFolderSelector from '../../components/report/bulk-report/FileSelector'; 
import FileListDisplay from '../../components/report/bulk-report/FileAndStudentMapper'; 

const FileSelector = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isFileSelector, setIsFileSelector] = useState(true);

  const handleFileSelection = (files) => {
    setSelectedFiles(files);
    setIsFileSelector(false); // Switch to the file list display
  };

  return (
    <>
    <Typography variant='h5' fontWeight={'bold'} width={'95%'} mx={'auto'}>Bulk Report Insertion</Typography>
    <Container>
      {isFileSelector ? (
        <FileFolderSelector onFileSelect={handleFileSelection} />
      ) : (
        <FileListDisplay files={selectedFiles} setFiles={setSelectedFiles} />
      )}
    </Container>
    </>
  );
};

export default FileSelector;

