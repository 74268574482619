import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ element, requiredPermissions }) => {
  const profileState = useSelector((state) => state.profile);
  const userPermissions = profileState?.user?.permission || [];
  const role = profileState?.user?.role;

  const hasPermission = requiredPermissions.some((perm) => userPermissions.includes(perm));

  return (hasPermission||role=='admin') ? element : <Navigate to="/not-authorized" />;
};

export default ProtectedRoute;
