import React, { useEffect, useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CardMedia,
  Menu,
  MenuItem,
  Box,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router";
import axios from "axios"; // Assuming you're using axios for API requests
import { apiConnector } from "../../services/ApiConnector";
import { categoryEndpoints } from "../../services/ApiEndPoints";
import toast from "react-hot-toast";
import AddIcon from "@mui/icons-material/Add";
import AddCategoryModal from './AddCategoryModal';
import { useSelector } from "react-redux";
import ViewCategoryModal from './ViewCategoryModal';


const CategoryPage = () => {
  const [loading,setLoading] = useState(false)
  const [categories, setCategories] = useState([]); // Store categories fetched from API
  const token = useSelector((state) => state.auth.token)
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [viewModalOpen, setViewModalOpen] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const navigate = useNavigate();

  // Fetch categories on initial render
  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true)
      const toastId = toast("Loading Categories");
      try {
        const response = await apiConnector("GET", `${categoryEndpoints.GET_ALL_CATEGORIES}`, null, { Authorization: `Bearer ${token}` });
        console.log(response)
        if (response.data.status) {
          setCategories(response.data?.data);
          toast.success("Categories Fetched Successfully!")
        } else {
          toast.error("Something went wrong while Fetching the categories!")
        }
      } catch (error) {
        toast.error("Categories Fetched UnSuccessfull!")
        console.error("Failed to fetch categories", error);
      } finally {
        toast.dismiss(toastId);
        setLoading(false)
      }
    };

    fetchCategories();
  }, []);

  // Handle menu open
  const handleMenuOpen = (event, categoryId) => {
    setAnchorEl(event.currentTarget);
    setSelectedCategoryId(categoryId);
  };

  // Handle menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle category deletion (optional)
  const handleDelete = async () => {
    if (!window.confirm("Are you Sure you want to delete this Category?")) return;
    const toastId = toast("Deleting Category");
    try {
      const response = await apiConnector(
        "DELETE", // Method for deletion
        `${categoryEndpoints.DELETE_CATEGORY}/${selectedCategoryId}`, // API endpoint with categoryId
        null,
        {
          Authorization: `Bearer ${token}`, // Pass the authorization token
        }
      );
      console.log(response)
      if (response.data.status) {
        // Assuming a successful response will have a truthy status
        toast.success("Category Deleted Successfully!");
        // Optionally, you can remove the deleted category from the UI here
        setCategories((prevCategories) =>
          prevCategories.filter((category) => category._id !== selectedCategoryId)
        );
      } else {
        toast.error("Something went wrong while deleting the category!");
      }
    } catch (error) {
      toast.error("Category Deletion Unsuccessful!");
      console.error("Failed to delete category", error);
    } finally {
      toast.dismiss(toastId); // Dismiss the toast
      handleMenuClose();
    }
  };


  // Handle navigation to category profile
  const handleView = () => {
    setViewModalOpen(true)
    handleMenuClose();
  };

  return (
    <div style={{ padding: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={5}>
        <Typography variant="h5" fontWeight="bold">
          All Categories <Typography component="span">({categories?.length} Categories Available)</Typography>
        </Typography>

        <Button
          variant="contained"
          color="error"
          startIcon={<AddIcon />}
          sx={{ backgroundColor: "#EF492E" }}
          onClick={() => {
            handleOpen();
          }}
        >
          Add Category
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
              <TableCell sx={{ fontWeight: 'bold' }}>Sr.No</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Title</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Category ID</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Parent Category</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>
            </TableRow>
          </TableHead>

          {
            categories?.length != 0 &&
            <TableBody>
              {categories?.map((category, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{category?.name}</TableCell>
                  <TableCell>{category?._id}</TableCell>
                  <TableCell>{category?.parentCategory?.name || <Typography sx={{ fontWeight: 'bold' }}>Prinicipal Category</Typography>}</TableCell>
                  <TableCell>
                    <MoreVertIcon
                      sx={{ cursor: 'pointer' }}
                      onClick={(event) => handleMenuOpen(event, category?._id)}
                    />
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && selectedCategoryId === category?._id}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleView}>View</MenuItem>
                      <MenuItem onClick={handleDelete}>Delete</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          }
        </Table>
        {
          categories?.length == 0 && <Typography variant="h4" display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={'center'} height={'400px'} width={'full'}>
          {
            loading?"loading...":"No Category Found! Please create One"
          }
          </Typography>
        }
      </TableContainer>

      <AddCategoryModal
        setCategories={setCategories}
        open={modalOpen}
        handleClose={handleClose}
        categories={categories}
      />
      <ViewCategoryModal
        open={viewModalOpen}
        handleClose={() => { setViewModalOpen(false); }}
        // categoryId={selectedCategoryId}
        // categories={categories}
        category={categories.find(item => item._id === selectedCategoryId)} // Use find instead of filter
      />

    </div>
  );
};

export default CategoryPage;
