import {  Routes, Route, Navigate } from "react-router-dom";
import AddSchoolPage from "../pages/school/AddSchoolPage";
import SchoolProfilePage from "../pages/school/SchoolProfilePage";
import AllSchoolPage from "../pages/school/AllSchoolPage";
import AddStudentPage from "../pages/student/AddStudentPage";
import StudentProfilePage from "../pages/student/StudentProfilePage";
import AllStudentPage from "../pages/student/AllStudentPage";
import AllTeacherPage from "../pages/teacher/AllTeacherPage";
import AllSurveyPage from "../pages/survey/AllSurvey";
import AddSurveyPage from "../pages/survey/AddSurvey";
import MainLayout from "../Layout/MainLayout";
import AuthLayout from "../Layout/AuthLayout";
import Dashboard from '../pages/Dashboard/Dashboard';
import AllCourse from '../pages/course/AllCourse';
import AllNotifications from '../pages/Notification/AllNotification'
import Auth from "../pages/auth/Auth";
import TeacherProfile from "../pages/teacher/TeacherProfile";
import Profile from '../pages/profile/Profile'
import SurveyDetailPage from "../pages/survey/SurveyDetailPage";
import NotificationSpecific from '../pages/Notification/NotificationSpecific'
import CreateNotification from '../pages/Notification/CreateNotification';
import AllReports from "../pages/Report/AllReports";
import ViewCourse from "../pages/course/ViewCourse";
import AddCourse from "../pages/course/AddCourse";
import AddTeacherPage from '../pages/teacher/AddTeacherPage'
import AddReport from '../pages/Report/AddReport';
import ReportSpecific from '../pages/Report/ReportSpecific';
import SurveyResponse from "../pages/survey/SurveyResponse";
import CourseStudent from '../pages/course/CourseStudent.jsx'
import CourseTeacher from "../pages/course/CourseTeacher.jsx";
import CourseCounsellor from '../pages/course/CourseCounsellors.jsx'
import AllCertification from '../pages/certification/AllCertification.jsx'
import CreateCertification from '../pages/certification/CreateCertification.jsx';
import ViewCertification from '../pages/certification/ViewCertification.jsx';
import AssignedStudentPage from "../components/teacher/AssignedStudentPage.jsx";
import AllBrands from '../pages/brand/AllBrands.jsx';
import AddBrands from '../pages/brand/AddBrands.jsx';
import BrandProfile from '../pages/brand/BrandProfilePage.jsx';
import AllCounsellors from '../pages/counselor/AllCounselors.jsx'
import AddCounsellor from '../pages/counselor/AddCounselor.jsx';
import CouncellorDetailPage from '../pages/counselor/CounselorDetailPage.jsx';
import CategoryListPage from '../common/category/CategoryListPage.jsx'
import AllStaff from '../pages/manage-staff/AllStaff.jsx';
import CreateStaff from '../pages/manage-staff/CreateStaff.jsx';
import NotAuthorized from "../pages/Extra_pages/NotAuthorized.jsx";
import UndefinedPage from '../pages/Extra_pages/Undefined.jsx'
import AddBulkReport from "../pages/Report/AddBulkReport.jsx";
import { useSelector } from "react-redux";

import ProtectedRoute from "./ProtectedRoute"; // import your ProtectedRoute component

export default function Router() {
  return (
    <Routes>
      {/* Public routes */}
      <Route element={<AuthLayout />}>
        <Route path="/login" element={<Auth />} />
        <Route path="/signup" element={<Auth />} />
      </Route>

      <Route path="/not-authorized" element={<NotAuthorized />} />

      {/* Protected routes */}
      <Route element={<MainLayout />}> {/* Top level component to test the login credentials*/}
        <Route path="/" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />

        <Route path="/addschool" element={<ProtectedRoute element={<AddSchoolPage />} requiredPermissions={["school-management"]} />} />
        <Route path="/schoolprofile/:id" element={<ProtectedRoute element={<SchoolProfilePage />} requiredPermissions={["school-management"]} />} />
        <Route path="/schools" element={<ProtectedRoute element={<AllSchoolPage />} requiredPermissions={["school-management"]} />} />

        <Route path="/brands" element={<ProtectedRoute element={<AllBrands />} requiredPermissions={["brand-management"]} />} />
        <Route path="/brandprofile/:id" element={<ProtectedRoute element={<BrandProfile />} requiredPermissions={["brand-management"]} />} />
        <Route path="/addbrand" element={<ProtectedRoute element={<AddBrands />} requiredPermissions={["brand-management"]} />} />

        <Route path="/counselors" element={<ProtectedRoute element={<AllCounsellors />} requiredPermissions={["counsellor-management"]} />} />
        <Route path="/addcounselor" element={<ProtectedRoute element={<AddCounsellor />} requiredPermissions={["counsellor-management"]} />} />
        <Route path="/counselorprofile/:id" element={<ProtectedRoute element={<CouncellorDetailPage />} requiredPermissions={["counsellor-management"]} />} />

        <Route path="/addstudent" element={<ProtectedRoute element={<AddStudentPage />} requiredPermissions={["student-management"]} />} />
        <Route path="/studentprofile/:id" element={<ProtectedRoute element={<StudentProfilePage />} requiredPermissions={["student-management"]} />} />
        <Route path="/students" element={<ProtectedRoute element={<AllStudentPage />} requiredPermissions={["student-management"]} />} />

        <Route path="/teachers" element={<ProtectedRoute element={<AllTeacherPage />} requiredPermissions={["teacher-management"]} />} />
        <Route path="/teacherprofile/:id" element={<ProtectedRoute element={<TeacherProfile />} requiredPermissions={["teacher-management"]} />} />
        <Route path="/add-teacher" element={<ProtectedRoute element={<AddTeacherPage />} requiredPermissions={["teacher-management"]} />} />
        <Route path="/teacher/:id/assigned-students" element={<ProtectedRoute element={<AssignedStudentPage />} requiredPermissions={["teacher-management"]} />} />
        
        <Route path="/surveys" element={<ProtectedRoute element={<AllSurveyPage />} requiredPermissions={["assessment-management"]} />} />
        <Route path="/survey/:id" element={<ProtectedRoute element={<SurveyDetailPage />} requiredPermissions={["assessment-management"]} />} />
        <Route path="/add-survey" element={<ProtectedRoute element={<AddSurveyPage />} requiredPermissions={["assessment-management"]} />} />
        <Route path="/survey-response/:responseId" element={<ProtectedRoute element={<SurveyResponse />} requiredPermissions={["assessment-management"]} />} />

        <Route path="/courses" element={<ProtectedRoute element={<AllCourse />} requiredPermissions={["course-management"]} />} />
        <Route path="/course-students/:courseId" element={<ProtectedRoute element={<CourseStudent />} requiredPermissions={["course-management"]} />} />
        <Route path="/course-teachers/:courseId" element={<ProtectedRoute element={<CourseTeacher />} requiredPermissions={["course-management"]} />} />
        <Route path="/course-counsellor/:courseId" element={<ProtectedRoute element={<CourseCounsellor />} requiredPermissions={["course-management"]} />} />
        <Route path="/course/:id" element={<ProtectedRoute element={<ViewCourse />} requiredPermissions={["course-management"]} />} />
        <Route path="/add-course" element={<ProtectedRoute element={<AddCourse />} requiredPermissions={["course-management"]} />} />
        <Route path="/category" element={<ProtectedRoute element={<CategoryListPage />} requiredPermissions={["course-management"]} />} />

        <Route path="/reports" element={<ProtectedRoute element={<AllReports />} requiredPermissions={["report-management"]} />} />
        <Route path="/report/:reportId" element={<ProtectedRoute element={<ReportSpecific />} requiredPermissions={["report-management"]} />} />
        <Route path="/add-report" element={<ProtectedRoute element={<AddReport />} requiredPermissions={["report-management"]} />} />
        <Route path="/add-bulk-report" element={<ProtectedRoute element={<AddBulkReport />} requiredPermissions={["report-management"]} />} />

        <Route path="/certifications" element={<ProtectedRoute element={<AllCertification />} requiredPermissions={["certificate-management"]} />} />
        <Route path="/certification/:id" element={<ProtectedRoute element={<ViewCertification />} requiredPermissions={["certificate-management"]} />} />
        <Route path="/add-certificate" element={<ProtectedRoute element={<CreateCertification />} requiredPermissions={["certificate-management"]} />} />

        <Route path="/notifications" element={<AllNotifications />} />
        <Route path="/notification/:notificationId" element={<NotificationSpecific />} />
        <Route path="/create-notification" element={<ProtectedRoute element={<CreateNotification />} requiredPermissions={["notification-management"]} />} />


        <Route path="/all-staff" element={<ProtectedRoute element={<AllStaff />} requiredPermissions={["staff-management"]} />} />
        <Route path="/add-staff" element={<ProtectedRoute element={<CreateStaff />} requiredPermissions={["staff-management"]} />} />
      </Route>

      {/* Catch-all route */}
      <Route path="/*" element={<UndefinedPage />} />
    </Routes>
  );
}
