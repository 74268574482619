import React, { useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Menu,
  MenuItem,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { FilterList as FilterIcon, ExpandLess, ExpandMore } from '@mui/icons-material';
import FilterCard from './FilterCard';
import toast from 'react-hot-toast';
import { apiConnector } from '../../../services/ApiConnector';
import { reportEndpoints } from '../../../services/ApiEndPoints';
import { useSelector } from 'react-redux';


import { useNavigate } from 'react-router-dom'
import axios from 'axios';

const FileListDisplay = ({ files, users, setFiles, setUsers }) => {
  const navigate = useNavigate()
  const [progress,setProgress] = useState(0);
  const token = useSelector((state) => state.auth.token)
  const [role, setRole] = useState('student');
  const [school, setSchool] = useState(null);
  const [counselor, setCounselor] = useState(null);
  const [brand, setBrand] = useState(null);
  const [course, setCourse] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null); // Controls the dropdown open state
  const [assigned, setAssigned] = useState([]); // State to hold assigned users and files
  const [selectedUser, setSelectedUser] = useState({}); // State to store selected user for each file
  const [openModal, setOpenModal] = useState(false); // State for confirmation modal
  const [unassignedFiles, setUnassignedFiles] = useState([]); // State for files without assignments

  const limit = 10;

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const loadMoreUsers = async () => {
    setPage((prev) => prev + 1);
  };

  const handleDropdownOpen = (event, index) => {
    setAnchorEl({ ...anchorEl, [index]: event.currentTarget });
  };

  const handleDropdownClose = (index) => {
    setAnchorEl({ ...anchorEl, [index]: null });
  };

  const handleAssignUserToFile = (userId, userName, userRole, file, index) => {
    setAssigned((prevAssigned) => {
      const updatedAssigned = prevAssigned.filter((item) => item.file !== file);
      return [...updatedAssigned, { userId, file, role: userRole, userName }];
    });

    setSelectedUser((prevSelected) => ({
      ...prevSelected,
      [index]: userName,
    }));
    handleDropdownClose(index);
  };

  const handleSubmit = () => {
    const unassigned = files.filter(
      (file) => !assigned.some((item) => item.file === file)
    );

    if (unassigned.length > 0) {
      setUnassignedFiles(unassigned);
      setOpenModal(true); // Open confirmation modal if there are unassigned files
    } else {
      // If all files are assigned, proceed with the upload
      console.log("All files assigned:", assigned);
      // Add logic to submit data here
      // setOpenModal(true); // Open confirmation modal if there are unassigned files
      handleConfirm();
    }
  };

  const handleConfirm = async () => { 
    setOpenModal(false);
  
    // Extract file and user data for form submission
    const filesArr = assigned.map((item) => item.file);
    const userData = assigned.map((item) => ({
      id: item.userId,
      role: item.role,
    }));
  
    console.log("filesArr", filesArr);
    console.log("userData", userData);
  
    // Initialize FormData
    const formData = new FormData();
  
    // Append the files as a separate array
    filesArr.forEach((file) => {
      formData.append("files", file); // Each file is added with the same key 'files'
    });
  
    formData.append("userId", JSON.stringify(userData));
  
    const toastId = toast.loading("Uploading Reports...");
  
    try {
      const response = await axios.post(
        reportEndpoints.CREATE_BULK_REPORT,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (progressEvent) => {
            // Calculate the percentage of upload completed
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            // Update the loading toast with the percentage
            toast.loading(`Uploading Reports... ${percentCompleted}%`, { id: toastId });
          },
        }
      );
  
      console.log(response);
      toast.success("Reports Created!");
      navigate('/reports');
    } catch (error) {
      console.error('Error uploading reports:', error);
      toast.error("An error occurred while uploading reports.");
    } finally {
      toast.dismiss(toastId);
    }
  };

  console.log(progress);

  return (
    <Box sx={{ p: 2 }}>
      {/* Filter Card */}
      <Card sx={{ mb: 2, p: 2, borderRadius: 2, boxShadow: 1, bgcolor: '#f5f5f5' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            <FilterIcon sx={{ mr: 1 }} /> Filters
          </Typography>
          <IconButton onClick={toggleFilter} size="small">
            {isFilterOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={isFilterOpen}>
          <Box sx={{ mt: 2 }}>
            <FilterCard
              page={page}
              setPage={setPage}
              limit={limit}
              setUsers={setUsers}
              role={role}
              setRole={setRole}
              school={school}
              setSchool={setSchool}
              counselor={counselor}
              setCounselor={setCounselor}
              brand={brand}
              setBrand={setBrand}
              course={course}
              users={users}
              setCourse={setCourse}
            />
          </Box>
        </Collapse>
      </Card>

      {/* File List */}
      <List sx={{ width: '100%' }}>
        {files?.map((file, index) => (
          <ListItem
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: 2,
              borderRadius: 1,
              boxShadow: 1,
              bgcolor: (index + 1) % 2 === 1 ? '#f0f4f8' : '#FFDFDF',
              mb: 1,
            }}
          >
            {/* File name */}
            <ListItemText
              primary={file.name}
              secondary={`Size: ${Math.round(file.size / 1024)} KB`}
              sx={{ flex: 1, pr: 2, width: "50%" }}
            />

            {/* Student dropdown trigger */}
            <Button
              onClick={(event) => handleDropdownOpen(event, index)}
              fullWidth
              sx={{ bgcolor: 'white', borderRadius: 1, width: '40%' }}
            >
              {selectedUser[index] || 'Select a User'}
            </Button>
            <Menu
              anchorEl={anchorEl?.[index] || null}
              open={Boolean(anchorEl?.[index])}
              onClose={() => handleDropdownClose(index)}
              MenuListProps={{
                sx: {
                  maxHeight: 300,
                },
              }}
            >
              {users?.map((student, idx) => (
                <MenuItem
                  key={idx}
                  value={student?._id}
                  onClick={() => handleAssignUserToFile(student._id, student?.name, student?.role, file, index)}
                >
                  {student?.name}
                </MenuItem>
              ))}
              <MenuItem
                onClick={loadMoreUsers}
                disabled={users?.length < page * limit}
                sx={{
                  fontWeight: 'bold',
                  color: 'red',
                  '&.Mui-disabled': {
                    color: 'grey',
                  },
                }}
              >
                Load More
              </MenuItem>
            </Menu>
          </ListItem>
        ))}
      </List>

      {/* Submit Button */}
      <Button variant="outlined" onClick={handleSubmit}>
        Submit
      </Button>

      {/* Confirmation Modal */}
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Unassigned Files Detected</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The following files have not been assigned to any user:
          </DialogContentText>
          <List>
            {unassignedFiles.map((file, index) => (
              <ListItem key={index}>
                <ListItemText primary={file.name} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default function FileListWithReports({ files, setFiles }) {
  const [users, setUsers] = useState([]);
  return <FileListDisplay files={files} setFiles={setFiles} users={users} setUsers={setUsers} />;
}
