import React, { useState, useEffect } from 'react';
import { TextField, Button, Paper, Box, Typography, Grid, IconButton, Checkbox, MenuItem, Select, InputLabel, FormControl, CircularProgress } from '@mui/material';
import { CloudUpload, Cancel } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import CourseSelectedDropDown from '../../common/CourseSelectedDropdown';
import { apiConnector } from '../../services/ApiConnector';
import { notificationEndpoints } from '../../services/ApiEndPoints';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast'
// import useFirebaseImageUpload from '../../hooks/useFirebaseImageUpload';
import useAwsFileUpload from '../../hooks/useAwsImageUpload'

const CreateNotification = () => {
  const { uploadFileToAws, uploading } = useAwsFileUpload();
  // const { uploadImageToFirebase, uploading } = useFirebaseImageUpload();
  const token = useSelector((state) => state.auth.token)
  const navigate = useNavigate();
  const [topic, setTopic] = useState('');
  const [course, setCourse] = useState(null);
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [teacherList, setTeacherList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectAllTeachers, setSelectAllTeachers] = useState(false);
  const [selectAllStudents, setSelectAllStudents] = useState(false);
  const [loading, setLoading] = useState(false);

  const [counselorList, setCounselorList] = useState([]);
  const [selectedCounselors, setSelectedCounselors] = useState([]);
  const [selectAllCounselors, setSelectAllCounselors] = useState(false);



  // Fetch student and teacher lists based on the selected course
  useEffect(() => {
    if (course) {
      fetchStudentAndTeacherList(course._id)
    }
  }, [course]);

  // const handleFileChange = (e) => {
  //   setFile(e.target.files[0]);
  // };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const img = new Image();
      const objectURL = URL.createObjectURL(selectedFile);
      img.src = objectURL;
  
      img.onload = () => {
        // Check the aspect ratio (width / height should be 2:1)
        const aspectRatio = img.width / img.height;
        console.log(img.width,img.height,aspectRatio);
        if (aspectRatio >=1.5) {
          // Aspect ratio is 2:1, proceed
          setFile(selectedFile);
        } else {
          // Aspect ratio is not 2:1, show error or handle it
          toast.error("The uploaded image should have aspect ratio greater then 1:5.");
        }
        
        // Clean up the object URL
        URL.revokeObjectURL(objectURL);
      };
    }
  };
  

  const handleSelectAllTeachers = (e) => {
    setSelectAllTeachers(e.target.checked);
    setSelectedTeachers(e.target.checked ? teacherList?.map(teacher => teacher._id) : []);
  };

  const handleSelectAllStudents = (e) => {
    setSelectAllStudents(e.target.checked);
    setSelectedStudents(e.target.checked ? studentList?.map(student => student._id) : []);
  };

  const handleSelectAllCounselors = (e) => {
    setSelectAllCounselors(e.target.checked);
    setSelectedCounselors(e.target.checked ? counselorList?.map(counselor => counselor._id) : []);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (topic === "" || message === "") {
      toast.error("Topic and message are required");
      return;
    }

    if (!course) {
      toast.error("Please select a course before submitting.");
      return;
    }

    if (!selectedTeachers.length && !selectedStudents.length && !selectedCounselors.length) {
      toast.error("Please select at least one teacher, student, or counselor.");
      return;
    }

    const toastId = toast.loading("Sending notification...");

    try {
      let imageUrl = "";

      if (file) {
        imageUrl = await uploadFileToAws(file);
        if (!imageUrl) {
          throw new Error("Failed to upload image");
        }
      }

      const notificationData = {
        name: topic,
        type: "Created By Admin Directly!",
        message,
        description,
        url: imageUrl,
        teacherIds: selectedTeachers,
        studentIds: selectedStudents,
        councellerIds: selectedCounselors,  // Add this for counselors
      };

      const response = await apiConnector(
        "POST",
        notificationEndpoints.CREATE_CUSTOM_NOTIFICATION,
        notificationData,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Notification created successfully!");
      } else {
        toast.error("Failed to create notification.");
      }

    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      toast.dismiss(toastId);
      navigate('/notifications');
    }
  };




  const fetchStudentAndTeacherList = async (courseId) => {
    setLoading(true);
    try {
      const response = await apiConnector(
        "GET",
        `${notificationEndpoints.GET_USERS_BY_COURSEID}?courseId=${courseId}`,
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      const { students, teachers, counceller } = response?.data?.data || {};

      setStudentList(students);
      setTeacherList(teachers);
      setCounselorList(counceller);  // Add this for counselors
    } catch (error) {
      console.error("FETCH LIST ERROR:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ width: "80%", margin: "auto", padding: 4 }}>
      <Typography variant="h5" fontWeight="bold" mb={3}>
        Create Notifications
      </Typography>

      <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off" sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <TextField
          size='small'
          label="Topic"
          variant="outlined"
          fullWidth
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          placeholder="Enter notification topic"
        />

        <TextField
          size='small'
          label="Message"
          variant="outlined"
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter notification message"
        />

        <Box>
          <Typography fontWeight={'bold'} mb={1}>Notification Description</Typography>
          <TextField
            multiline
            minRows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Notification description"
            fullWidth
            variant="outlined"
            sx={{ padding: '2px', borderRadius: '10px' }}
          />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography mb={1}>Upload File (Optional)</Typography>
            <label htmlFor="upload-file">
              <input
                id="upload-file"
                type="file"
                accept="image/*" // Accepts only image files
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <IconButton
                color="primary"
                component="span"
                sx={{ border: "1px dashed grey", padding: "7px", width: "100%", borderRadius: "10px", justifyContent: "flex-start" }}
              >
                <CloudUpload sx={{ mr: 2, fontSize: "19px" }} />
                {file ? <Typography sx={{ fontSize: '15px' }}>{file.name}</Typography> : <Typography sx={{ fontSize: '15px' }}>Choose File</Typography>}
              </IconButton>
              <Typography variant="caption" display="block" color="textSecondary">
                Supported Files: jpg, png({"aspect ratio>=1.5:1"})
              </Typography>
            </label>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography mb={1}>Course</Typography>
            <CourseSelectedDropDown size={'small'} course={course} setCourse={setCourse} />
          </Grid>

          {/* Student Dropdown */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth disabled={!course}>
              <InputLabel>Students{loading && <CircularProgress />}</InputLabel>

              <Select
                size='small'
                multiple
                value={selectedStudents}
                onChange={(e) => setSelectedStudents(e.target.value)}
                renderValue={(selected) => selected.length ? 'Selected Students' : 'Select students'}
              >
                <MenuItem value="selectAll">
                  <Checkbox
                    checked={selectAllStudents}
                    onChange={handleSelectAllStudents}
                  />
                  Select All
                </MenuItem>
                {studentList?.map((student) => (
                  <MenuItem key={student._id} value={student._id}>
                    <Checkbox checked={selectedStudents.includes(student._id)} />
                    {student.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Teacher Dropdown */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth disabled={!course}>
              <InputLabel>Teachers {loading && <CircularProgress />}</InputLabel>
              <Select
                size='small'
                multiple
                value={selectedTeachers}
                onChange={(e) => setSelectedTeachers(e.target.value)}
                renderValue={(selected) => selected.length ? 'watch Selected Teachers' : 'Select teachers'}
              >
                <MenuItem value="selectAll">
                  <Checkbox
                    checked={selectAllTeachers}
                    onChange={handleSelectAllTeachers}
                  />
                  Select All
                </MenuItem>
                {teacherList?.map((teacher) => (
                  <MenuItem key={teacher._id} value={teacher._id}>
                    <Checkbox checked={selectedTeachers.includes(teacher._id)} />
                    {teacher.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Counsellor Dropdown */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth disabled={!course}>
              <InputLabel>Counsellors {loading && <CircularProgress />}</InputLabel>
              <Select
                size="small"
                multiple
                value={selectedCounselors}
                onChange={(e) => setSelectedCounselors(e.target.value)}
                renderValue={(selected) => selected.length ? 'Selected Counselors' : 'Select counselors'}
              >
                <MenuItem value="selectAll">
                  <Checkbox
                    checked={selectAllCounselors}
                    onChange={handleSelectAllCounselors}
                  />
                  Select All
                </MenuItem>
                {counselorList?.map((counselor) => (
                  <MenuItem key={counselor._id} value={counselor._id}>
                    <Checkbox checked={selectedCounselors.includes(counselor._id)} />
                    {counselor.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

        </Grid>

        <Box display="flex" justifyContent="space-between" mt={4}>
          <Button
            variant="outlined"
            startIcon={<Cancel />}
            onClick={() => { navigate("/notifications") }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="warning"
            type='submit'
            sx={{ backgroundColor: "#EF492E", "&:hover": { backgroundColor: "#d14026" } }}
          >
            Send Notification
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};



export default CreateNotification;

