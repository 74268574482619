import React, { useState, useEffect } from 'react';
import { Grid, FormControl, TextField, Autocomplete, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { apiConnector } from '../services/ApiConnector';
import { counselorEndpoints } from '../services/ApiEndPoints';

const CounselorSelector = ({ size, counselor, setCounselor }) => {
  const [counselorOptions, setCounselorOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const token = useSelector((state) => state.auth.token);

  // Fetch all counselors data on mount
  useEffect(() => {
    const fetchCounselors = async () => {
      setLoading(true);
      try {
        const response = await apiConnector(
          'GET',
          counselorEndpoints.GET_COUNSELLORS_NAME, // Adjust endpoint as necessary
          null,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        );
        console.log(response)
        setCounselorOptions(response.data.data); // Assuming data structure contains counselor array in data
      } catch (error) {
        console.error('Error fetching counselors:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCounselors();
  }, [token]);

  // Filter counselor options based on search term
  const filteredOptions = counselorOptions.filter((counselor) =>
    counselor.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Grid item xs={12} sm={6}>
      <FormControl fullWidth variant="outlined">
        <Autocomplete
          size={size || ''}
          options={filteredOptions}
          getOptionLabel={(option) => option.name || ''}
          loading={loading}
          value={counselor}
          onChange={(event, newValue) => setCounselor(newValue)}
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              label="Counsellor"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          )}
        />
      </FormControl>
    </Grid>
  );
};

export default CounselorSelector;
