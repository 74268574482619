import React, { useEffect, useState } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, IconButton, Menu, MenuItem, Button, Typography, Box,
  TextField, InputAdornment,
  FormControl,
  Select
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from 'use-debounce';
import { toast } from "react-hot-toast";

import { fetchStaff, deleteStaff } from '../../services/Operations/StaffOperation';
import { clearStaff, setStaff, setSearchTerm, updateCurrentPage } from '../../Store/Slices/staffSlice';
import { staffEndpoints } from "../../services/ApiEndPoints";
import { apiConnector } from "../../services/ApiConnector";
import StaffProfileAndEditModal from '../../components/manage-staff/StaffProfileAndEditModal'

const StaffTable = ({
  totalStaff,
  staffPerPage,
  handleChange,
  handleLoadMore,
  staff,
  currentPage,
  totalPages,
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [showProfileModal,setProfileModal] = useState(false);

  const handleMenuClick = (event, staff) => {
    setAnchorEl(event.currentTarget);
    setSelectedStaff(staff);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedStaff(null);
  };

  const handleView = () => {
    if (selectedStaff) {
      navigate(`/staff/${selectedStaff._id}`)
    }
    handleMenuClose();
  };

  const handleDelete = async () => {
    handleMenuClose();
    if (window.confirm("Are you sure you want to delete this staff member?")) {
      const toastId = toast.loading("Deleting Staff Member...");

      try {
        const endpoint = `${staffEndpoints.DELETE_STAFF}/${selectedStaff._id}`;
        await apiConnector("DELETE", endpoint, {}, { Authorization: `Bearer ${token}` });

        toast.success("Staff member deleted successfully");
        const updatedStaffList = staff.filter((staff) => staff._id !== selectedStaff._id);
        dispatch(setStaff({
          staff: updatedStaffList,
          totalStaff: totalStaff - 1,
          totalPages,
          currentPage,
        }));
      } catch (error) {
        console.error("Error deleting staff member:", error);
        toast.error("Failed to delete staff member");
      } finally {
        toast.dismiss(toastId);
        handleMenuClose();
      }
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FFBE9D" }}>
              <TableCell sx={{ fontWeight: "bold" }}>Sr</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Role</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Phone</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {staff?.map((staff, index) => (
              <TableRow key={staff._id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{staff?.name}</TableCell>
                <TableCell>{staff?.role}</TableCell>
                <TableCell>{staff?.email}</TableCell>
                <TableCell>{staff?.phone ||"N/A"}</TableCell>

                <TableCell>
                  <IconButton onClick={(event) => handleMenuClick(event, staff)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    <MenuItem onClick={()=>{
                      setProfileModal(true)
                      handleMenuClose();
                      setAnchorEl(null);
                      }}>View Details</MenuItem>
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        mt={3}
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
        gap={"10px"}
        textAlign="center"
      >
        <Typography>Page - {currentPage} / {totalPages}</Typography>
        <Button
          onClick={handleLoadMore}
          disabled={currentPage >= totalPages}
          variant="outlined"
          color="primary"
        >
          Load More
        </Button>

        <FormControl>
          <Select
            value={staffPerPage}
            onChange={handleChange}
            displayEmpty
            size="small"
            renderValue={(value) => `Page Size - ${value}`}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <StaffProfileAndEditModal open={showProfileModal} setProfileModal={setProfileModal} adminData={selectedStaff}/>
    </>
  );
};

const AllStaff = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const { staff, totalStaff, currentPage, loading, totalPages, searchTerm } = useSelector((state) => state.staff);
  const [staffPerPage, setStaffPerPage] = useState(8); // Adjust this number as needed
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  console.log(staff)

  const handleChange = (event) => {
    setStaffPerPage(event.target.value);
  };

  useEffect(() => {
    if (!loading) {
      dispatch(fetchStaff(token, 1, staffPerPage, debouncedSearchTerm, true));
    }
  }, [debouncedSearchTerm, dispatch, staffPerPage]);

  useEffect(() => {
    if (!loading && currentPage > 1) {
      dispatch(fetchStaff(token, currentPage, staffPerPage, debouncedSearchTerm));
    }
  }, [currentPage, debouncedSearchTerm, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearStaff());
    };
  }, [dispatch]);

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
    }
  };

  const handleSearchChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
    dispatch(updateCurrentPage(1)); // Reset to first page on new search
  };

  return (
    <div style={{ padding: '20px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={5}>
        <Typography variant="h5" fontWeight="bold">
          All Staff <Typography component="span">({totalStaff} Staff Members Available)</Typography>
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            variant="outlined"
            placeholder="Search Staff"
            onChange={handleSearchChange}
            value={searchTerm}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="error"
            startIcon={<AddIcon />}
            sx={{ backgroundColor: "#EF492E" }}
            onClick={() => { navigate('/add-staff') }}
          >
            Add Staff
          </Button>
        </Box>
      </Box>
      <StaffTable
        totalStaff={totalStaff}
        staffPerPage={staffPerPage}
        handleChange={handleChange}
        handleLoadMore={handleLoadMore}
        staff={staff}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    </div>
  );
}

export default AllStaff;
