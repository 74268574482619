import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

// Import reducers from slices
import authReducer from "../Store/Slices/authSlice";
import profileReducer from "../Store/Slices/profileSlice";
import dashboardReducer from '../Store/Slices/dashboardSlice';
import schoolReducer from '../Store/Slices/schoolSlice';
import studentReducer from '../Store/Slices/studentSlice';
import surveyReducer from '../Store/Slices/surveySlice';  
import teacherReducer from '../Store/Slices/teacherSlice';
import notificationReducer from '../Store/Slices/notificationSlice';
import reportReducer from '../Store/Slices/reportSlice';
import courseReducer from '../Store/Slices/courseSlice';
import certificationReducer from '../Store/Slices/certificationSlice';
import brandReducer from '../Store/Slices/brandSlice';
import counselorReducer from '../Store/Slices/counselorSlice'
import staffReducer from '../Store/Slices/staffSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,            
    profile: profileReducer,     
    dashboardData: dashboardReducer,
    schools: schoolReducer,
    students: studentReducer,
    courses:courseReducer,
    surveys: surveyReducer,         
    teachers: teacherReducer,
    notifications: notificationReducer,
    reports: reportReducer,
    certifications:certificationReducer,
    brands:brandReducer,
    counselors:counselorReducer,
    staff:staffReducer
  },
});

// Set up listeners for RTK Query (if used)
setupListeners(store.dispatch);
