import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Avatar, Grid, FormControl, MenuItem, Select, InputLabel, IconButton, Tooltip, Chip } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { toast } from 'react-hot-toast';
import { staffEndpoints } from '../../services/ApiEndPoints';
import { apiConnector } from '../../services/ApiConnector';
import { useSelector } from 'react-redux';
import useAwsFileUpload from '../../hooks/useAwsImageUpload';

const ViewProfileModal = ({ setProfileModal, open, adminData }) => {
    const { uploadFileToAws, uploading } = useAwsFileUpload();
    const token = useSelector((state) => state.auth.token)
    const [isEditing, setIsEditing] = useState(false);
    const [file, setFile] = useState(null);
    const  [adminId,setAdminId]=useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        role: 'sub-admin',
        permission: [],
        profilePhoto: '',
    });

    useEffect(() => {
        if (adminData) {
            setAdminId(adminData?._id);
            setFormData({
                name: adminData.name || '',
                email: adminData.email || '',
                phone: adminData.phone || '',
                password: '',
                role: adminData.role || 'sub-admin',
                permission: adminData.permission || [],
                profilePhoto: adminData.profilePhoto || '',
            });
        }
    }, [adminData]);

    console.log(adminData)

    const onUpdate = async () => {
        setIsEditing(false);
        const toastId = toast.loading("Updating Staff data...");
        
        try {
            const response = await apiConnector(
                "PUT",
                `${staffEndpoints.UPDATE_STAFF}/${adminId}`,
                formData,
                {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }
            );
            // setCertificationData(response?.data?.data);
            if (response?.data?.status) {
                setProfileModal(false);
                 window.location.reload();
            }
            console.log(response)
            toast.success("Certification updated successfully");
        } catch (error) {
            toast.error(`Failed to update certification: ${error.message}`);
        } finally {

            toast.dismiss(toastId);
        }
    };

    const handleClose = () => {
        setIsEditing(false);
        setProfileModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleEditClick = () => setIsEditing(!isEditing);

    const handleUpdateClick =async() => {
        if (!formData.name || !formData.email) {
            toast.error("Name and email are required fields");
            return;
        }
        if(file){
            try{
              let imageUrl = await uploadFileToAws(file);
              formData.profilePhoto=imageUrl;
            }catch{
               toast.error("Something went wrong");
               return;
            }
        }
        onUpdate(formData);
        setIsEditing(false);
    };

    const handlePermissionChange = (e) => {
        const { value } = e.target;
        setFormData((prev) => ({ ...prev, permission: typeof value === 'string' ? value.split(',') : value }));
    };


    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle textAlign="center">Profile Details</DialogTitle>
            <DialogContent dividers>
                <Box display="flex" justifyContent="center" alignItems="center" mb={2} position="relative">
                    <Avatar src={formData.profilePhoto} alt={formData.name} sx={{ width: 100, height: 100 }} />
                    {isEditing && (
                        <Tooltip title="Edit Photo">
                            <IconButton
                                component="label"
                                sx={{ position: 'absolute', bottom: 0, right: 0 }}
                            >
                                <EditIcon color="action" />
                                <input
                                    type="file"
                                    accept="image/*"
                                    hidden
                                    onChange={(e) => {
                                        const selectedFile = e.target.files[0];
                                        if (selectedFile) {
                                            setFile(selectedFile);  // Sets the file if needed for upload purposes
                                            setFormData({
                                                ...formData,
                                                profilePhoto: URL.createObjectURL(selectedFile)  // Generates a preview URL for the image
                                            });
                                        }
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            fullWidth
                            disabled={!isEditing}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            fullWidth
                            disabled={!isEditing}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            fullWidth
                            disabled={!isEditing}
                        />
                    </Grid>

                    {isEditing && (
                        <Grid item xs={12}>
                            <TextField
                                label="Password"
                                name="password"
                                type="password"
                                value={formData.password}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Role</InputLabel>
                            <Select
                                name="role"
                                value={formData.role}
                                onChange={handleChange}
                                disabled={!isEditing}
                            >
                                <MenuItem value="admin">Admin</MenuItem>
                                <MenuItem value="sub-admin">Sub-Admin</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                      formData?.role!='admin'&&
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Permissions</InputLabel>
                            <Select
                                multiple
                                name="permission"
                                value={formData.permission}
                                onChange={handlePermissionChange}
                                disabled={!isEditing}
                                renderValue={(selected) => (
                                    <Box display="flex" flexWrap="wrap" gap={0.5}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} style={{ backgroundColor: 'rgba(255,0,0,0.1)', color: 'red' }} />
                                        ))}
                                    </Box>
                                )}
                            >
                                {['student-management', 'school-management', 'course-management', 'teacher-management',  'assessment-management', 'brand-management', 'certificate-management', 'report-management','counceller-management','notification-management'].map((permission) => (
                                    <MenuItem key={permission} value={permission}>
                                        {permission}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">Close</Button>
                {isEditing ? (
                    <Button onClick={handleUpdateClick} variant="contained" color="primary">Update Details</Button>
                ) : (
                    <Button onClick={handleEditClick} variant="contained" color="secondary">Edit Details</Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ViewProfileModal;
