import React, { useState } from "react";
import { toast } from "react-hot-toast";
import {
  Container,
  Typography,
  Stack,
  Grid,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Delete } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiConnector } from "../../services/ApiConnector";
import { teacherEndpoints } from "../../services/ApiEndPoints";
import SchooleSelectorDropdown from '../../common/SchoolSelectorDropdown';
import { stateOptions } from "../../data/data";
import { teacherOptions } from "../../data/data";
import BrandSelector from "../../common/BrandSelectorDropdown";
// import useFirebaseImageUpload from "../../hooks/useFirebaseImageUpload";
import useAwsFileUpload from '../../hooks/useAwsImageUpload'

const AddTeacherForm = () => {
  // const { uploadImageToFirebase, uploading } = useFirebaseImageUpload();  // Destructure hook
  const {uploadFileToAws,uploading} = useAwsFileUpload();
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Form Data
  const [teacherName, setTeacherName] = useState("");
  const [mid,setMid] = useState(null);
  const [brand,setBrand] = useState(null);
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [teacherPhoto, setTeacherPhoto] = useState(null);
  const [school, setSchool] = useState("");
  const [teacherType, setTeacherType] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [qualifications, setQualifications] = useState([{ course: '', degreeType: '', passoutYear: '' }]);

  const handlePhotoChange = (e) => {
    setTeacherPhoto(e.target.files[0]);
  };

  const handleQualificationChange = (index, event) => {
    const values = [...qualifications];
    values[index][event.target.name] = event.target.value;
    setQualifications(values);
  };

  const handleAddQualification = () => {
    setQualifications([...qualifications, { course: '', degreeType: '', passoutYear: '' }]);
  };

  const handleRemoveQualification = (index) => {
    const values = [...qualifications];
    values.splice(index, 1);
    setQualifications(values);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const toastId = toast.loading("Loading...");
    setLoading(true);
  
    // Regex patterns for validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const phoneRegex = /^[6-9]\d{9}$/; // For valid 10-digit Indian contact numbers starting with 6-9
    const pincodeRegex = /^[1-9][0-9]{5}$/; // For valid 6-digit Indian pincodes
    const nameRegex = /^[a-zA-Z\s]{2,50}$/; // For validating teacher's name (letters and spaces, min 2 chars)
  
    // Validate teacher's name format
    if (!nameRegex.test(teacherName)) {
      toast.error("Invalid teacher name format. Only letters and spaces are allowed.");
      toast.dismiss(toastId);
      setLoading(false);
      return;
    }
  
    // Validate email format
    if (email && !emailRegex.test(email)) {
      toast.error("Invalid email format.");
      toast.dismiss(toastId);
      setLoading(false);
      return;
    }
  
    // Validate contact number format
    if (contactNo && !phoneRegex.test(contactNo)) {
      toast.error("Invalid contact number. Must be a valid 10-digit number.");
      toast.dismiss(toastId);
      setLoading(false);
      return;
    }
  
    // Validate pincode format
    if (pincode && !pincodeRegex.test(pincode)) {
      toast.error("Invalid pincode. Must be a 6-digit number.");
      toast.dismiss(toastId);
      setLoading(false);
      return;
    }
  
    try {
      let imageUrl = "";
  
      if (teacherPhoto) {
        // Use the hook to upload the image
        imageUrl = await uploadFileToAws(teacherPhoto);
        if (!imageUrl) {
          throw new Error("Failed to upload image");
        }
      }
  
      // Prepare teacher data
      const teacherData = {
        name: teacherName,
        email,
        phone: contactNo,
        schoolId: school._id,
        teacherType,
        address: homeAddress,
        brandId:brand?._id,
        state,
        mid,
        city,
        landmark,
        pincode,
        Qualification: qualifications,
        profilePhoto: imageUrl, // Store uploaded image URL
      };
  
      // API call to create teacher
      const response = await apiConnector(
        "POST",
        `${teacherEndpoints.CREATE_TEACHER}`,
        teacherData,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
  
      toast.dismiss(toastId);
      toast.success("Teacher Created Successfully!", { id: toastId });
      navigate('/teachers');
  
    } catch (error) {
      toast.dismiss(toastId);
      setLoading(false);
      toast.error(`Failed to create Teacher. ${error?.response?.data?.message}`, { id: toastId });
    }
  };
  

  return (
    <Container maxWidth="md" sx={{ my: '20px' }}>
      <Typography variant="h4" align="center" sx={{ marginTop: "1rem", color: "#FF6636" }}>
        Add New Teacher
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack display={'flex'} flexDirection={'column'} gap={'10px'}>
          <Typography variant="h6">Teacher Details</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required={true}
                type="text"
                label="Teacher Name"
                variant="outlined"
                fullWidth
                value={teacherName}
                onChange={(e) => setTeacherName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="email"
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="mid"
                label="MID"
                variant="outlined"
                fullWidth
                value={mid}
                onChange={(e) => setMid(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  border: "1px solid #cccccc",
                }}
              >
                <Button
                  component="label"
                  sx={{
                    color: "#000000",
                    border: "none",
                    textTransform: "none",
                    backgroundColor: "#f5f5f5",
                    padding: "4px 8px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "0.875rem",
                  }}
                  startIcon={<AttachFileIcon />}
                >
                  Choose Image
                  <input
                    type="file"
                    hidden
                    accept=".jpg, .png"
                    onChange={handlePhotoChange}
                  />
                </Button>
                <Typography
                  variant="caption"
                  sx={{ color: "#888888", marginLeft: "8px" }}
                >
                  {
                        uploading?"Uploading":<>{teacherPhoto?teacherPhoto.name:"Supported Files: .jpg, .png"} </>
                      }  
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                label="Contact No."
                variant="outlined"
                fullWidth
                value={contactNo}
                onChange={(e) => setContactNo(e.target.value)}
              />
            </Grid>
            <SchooleSelectorDropdown  setSchool={setSchool} school={school} />
            <BrandSelector brand={brand} setBrand={setBrand}/>
           
            <Grid item xs={12} sm={6}>
              <FormControl required fullWidth variant="outlined">
                <InputLabel>Select Teacher-Type</InputLabel>
                <Select
                  value={teacherType}
                  
                  onChange={(e) => setTeacherType(e.target.value)}
                >
                  {/* Populate state options dynamically */}
                  {teacherOptions?.map((option,index) => (
                    <MenuItem key={index}  value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} >
              <TextField
                required={true}
                type="text"
                label="Home Address"
                variant="outlined"
                fullWidth
                value={homeAddress}
                onChange={(e) => setHomeAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Select State</InputLabel>
                <Select
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  {/* Populate state options dynamically */}
                  {stateOptions?.map((option,index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                type="text"
                label="City"
                variant="outlined"
                fullWidth
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                type="text"
                label="Landmark"
                variant="outlined"
                fullWidth
                value={landmark}
                onChange={(e) => setLandmark(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                type="text"
                label="Pincode"
                variant="outlined"
                fullWidth
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Qualifications
              </Typography>
            </Grid>
            {qualifications.map((qualification, index) => (
              <Grid xs={12} item display={'flex'} gap={'12px'} key={index}>
                <Grid item xs={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Course</InputLabel>
                    <Select
                      name="course"
                      value={qualification.course}
                      onChange={(event) => handleQualificationChange(index, event)}
                    >
                      <MenuItem value="">Select Course</MenuItem>
                      <MenuItem value="Course1">Course 1</MenuItem>
                      <MenuItem value="Course2">Course 2</MenuItem>
                      {/* Add more options as needed */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Degree Type</InputLabel>
                    <Select
                      name="degreeType"
                      value={qualification.degreeType}
                      onChange={(event) => handleQualificationChange(index, event)}
                    >
                      <MenuItem value="">Select Degree Type</MenuItem>
                      <MenuItem value="Bachelors">Bachelors</MenuItem>
                      <MenuItem value="Masters">Masters</MenuItem>
                      {/* Add more options as needed */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    label="Passout Year"
                    name="passoutYear"
                    variant="outlined"
                    fullWidth
                    value={qualification.passoutYear}
                    onChange={(event) => handleQualificationChange(index, event)}
                  />
                </Grid>
                <IconButton onClick={() => handleRemoveQualification(index)}>
                  <Delete />
                </IconButton>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={handleAddQualification}
                sx={{ marginTop: "1rem" ,backgroundColor:'#EF492E',color:'white',":hover":{color:'white',backgroundColor:"#EF492E"}  }}
              >
                Add More
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{backgroundColor:'#EF492E',marginTop:"1rem",color:'white',":hover":{color:'white',backgroundColor:"#EF492E"}}} 
                disabled={loading}
              >
                {loading ? "Loading..." : "Add Teacher"}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </form>
    </Container>
  );
};

export default AddTeacherForm;
