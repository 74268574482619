import React, { useState } from 'react';
import { Box, FormControlLabel, Checkbox, Button, Typography, Grid } from '@mui/material';
import axios from 'axios'; // Import axios for API call
import { updateCounselorPermissions } from '../../../services/Operations/CounselorOperation';
import { useSelector } from 'react-redux';

const allPermissionsList = [
  'student-management',
  // 'school-management',
  // 'course-management',
  // 'teacher-management',
  // 'lecture-management',
  'assessment-management',
  // 'brand-management',
  // 'certificate-management',
  'report-management',
  // 'materials-management',
];

const Permission = ({ permission, userId }) => {
  const token = useSelector((state) => state.auth.token)
  const [loading,setLoading] = useState(false);
  // State to manage included and revoked permissions
  const [includedPermissions, setIncludedPermissions] = useState(permission);
  const [revokedPermissions, setRevokedPermissions] = useState([]);

  // Edit mode state
  const [editMode, setEditMode] = useState(false);

  // Toggle permission change
  const handlePermissionChange = (perm) => {
    if (includedPermissions.includes(perm)) {
      // Remove from included and add to revoked
      setIncludedPermissions(includedPermissions.filter((p) => p !== perm));
      setRevokedPermissions([...revokedPermissions, perm]);
    } else {
      // Remove from revoked and add to included
      setRevokedPermissions(revokedPermissions.filter((p) => p !== perm));
      setIncludedPermissions([...includedPermissions, perm]);
    }
  };

  // API call to save permissions
  const handleSavePermissions = async () => {
    setLoading(true)
    await updateCounselorPermissions(token, userId, includedPermissions, revokedPermissions)
    setLoading(false)
    setEditMode(false)
  };

  return (
    <Box>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} width={'90%'}>
        <Typography variant="h6" fontWeight={'bold'}>Permissions</Typography>
        {/* Button to toggle edit mode */}
        {!editMode && <Button variant="outlined" onClick={() => setEditMode(!editMode)}>
          Edit Permissions
        </Button>
        }
      </Box>
      {/* Display permissions */}
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {allPermissionsList.map((perm) => (
          <Grid item xs={12} sm={4} key={perm}>  {/* 3 items per row (sm={4} means 12/4=3 per row) */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={includedPermissions.includes(perm)} // Check if it's included
                  onChange={() => handlePermissionChange(perm)}
                  disabled={!editMode} // Disable in non-edit mode
                />
              }
              label={perm}
            />
          </Grid>
        ))}
      </Grid>

      {/* Save Permissions button */}
      {editMode && (
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={handleSavePermissions}
          sx={{ marginTop: 2 }}
        >
          Save Permissions
        </Button>
      )}
    </Box>
  );
};

export default Permission;
