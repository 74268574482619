import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Divider, Paper } from "@mui/material";
import { toast } from "react-hot-toast";
import { apiConnector } from "../../services/ApiConnector";
import { notificationEndpoints } from "../../services/ApiEndPoints";
import {useSelector} from 'react-redux'


const NotificationSpecific = () => {
  const [notificationDetail, setNotificationDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { notificationId } = useParams();
  const token = useSelector((state) => state.auth.token); // Assuming you use Redux for authentication

  useEffect(() => {
    const fetchNotificationDetail = async () => {
      setLoading(true);
      setError(null);
      const toastId = toast.loading("Loading notification...");

      try {
        const response = await apiConnector(
          "GET",
          `${notificationEndpoints.GET_NOTIFICATION_DETAIL}?notificationId=${notificationId}`,
          null,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        );
        console.log(response)
        setNotificationDetail(response?.data);
        toast.success("Notification loaded successfully");
      } catch (error) {
        setError(`Failed to load notification: ${error.message}`);
        toast.error(`Failed to load notification: ${error.message}`);
        console.error("FETCH NOTIFICATION ERROR:", error);
      } finally {
        setLoading(false);
        toast.dismiss(toastId);
      }
    };

    fetchNotificationDetail();
  }, [notificationId, token]);


  return (
    <Paper elevation={3} sx={{ width: "90%", mx: "auto", p: 4 }}>
      <Typography variant="h4" fontWeight="bold" color="black" borderBottom={2} borderColor="#FF6636" pb={2}>
        {notificationDetail?.type}
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
        <Typography variant="h5" fontWeight="bold" color="#EF492E" sx={{ width: "70%" }}>
          {notificationDetail?.name}
        </Typography>
        <Typography color="textSecondary" sx={{ width: "30%", textAlign: "right" }}>
          {notificationDetail?.createdAt}
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Typography variant="h6" fontWeight="bold">
        Message: {notificationDetail?.message}
      </Typography>
      <Typography color="textSecondary" paragraph>
        Description: {notificationDetail?.description}
      </Typography>
      {
        notificationDetail?.url && 
      <Box display="flex" justifyContent="start" mt={3}>
        <img src={notificationDetail?.url} alt="Notification Image" style={{ width: 200, height: 200 }} />
      </Box>
      }
    </Paper>
  );
};

export default NotificationSpecific;
