import React, { useState } from 'react';
import {
  Box, TextField, RadioGroup, FormControlLabel, Radio,
  Checkbox, Button, IconButton, Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-hot-toast';
import { apiConnector } from '../../services/ApiConnector';
import { surveyEndpoints } from '../../services/ApiEndPoints';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const style = {
  color: '#F84135',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  backgroundColor: '#fff',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  borderRadius: '10px',
};


const QuestionForm = ({ questionaireType, id, setQuestionType, questionType, handleClose, questions, setQuestions }) => {
  const token = useSelector((state) => state.auth.token);
  const [questionText, setQuestionText] = useState('');
  const [options, setOptions] = useState([]);
  const [required, setRequired] = useState(false);
  const [marks, setMarks] = useState(1);
  const [negativeMark, setNegativeMark] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState(options[0] || null)
  const [attemptNumber, setAttemptNumber] = useState(1);
  const [ansLength,setAnsLength] = useState(50);

  const handleOptionChange = (index, event) => {
    const newOptions = [...options];
    newOptions[index].value = event.target.value;
    setOptions(newOptions);
  };

  const handleAddOption = () => {
    setOptions([...options, { value: '' }]);
  };

  const handleRemoveOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  const addQuestion = async (newQuestion) => {
    const toastId = toast.loading("Adding Qustion Please Wait...");

    try {
      const response = await apiConnector(
        "POST",
        `${surveyEndpoints.ADD_QUESTION}`,
        newQuestion,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );


      console.log(response)
      if (response?.data?.status) {
        setQuestions([...questions, response?.data?.data]);
        setQuestionText('');
        setOptions([]);
        setQuestionType('');
        handleClose();
      }
      toast.success("Question Added successfully");

    } catch (error) {
      toast.error(`Failed to Add Question: ${error.message}`);
    } finally {
      toast.dismiss(toastId);
    }
  };

  const handleAddQuestion = async () => {
    if (!questionText.trim()) return toast.error("Question can't be empty");

    const newQuestion = {
      type: questionType,
      title: questionText,
      surveyId: id,
      options: (questionType === 'multiple-choice' || questionType === 'single-choice') ? options : [],
      required,
      negativeMark: "",
      questionMark: "",
      correctAnswer: "",
      attemptCount: "",
      ansLength:"",
    };
    if (questionaireType == 'quiz') {
      newQuestion.negativeMark = negativeMark.toString();
      newQuestion.questionMark = marks;
      newQuestion.correctAnswer = correctAnswer
      newQuestion.attemptCount = attemptNumber
    }
    // Adding a  word limit in brief type questions 
    console.log(questionType)
    if(questionType=='Brief-answer'){
       newQuestion.ansLength=ansLength;
    }
    newQuestion.options = options;
    if ((newQuestion.type == 'Multiple-choice' || newQuestion.type == 'Single-choice') && newQuestion?.options.length == 0) {
      toast.error("Pleasee Create Atlease option");
      return;
    }
    if (questionaireType == 'quiz') {
      if (newQuestion?.correctAnswer == null) {
        toast.error("There Should be atlease One Correct Ans");
        return;
      }
      if (newQuestion?.questionMark == "" || newQuestion?.negativeMark == "") {
        toast.error("Please add QuestionMark and Negative Mark");
        return;
      }
    }
    await addQuestion(newQuestion);
  };


  return (
    <Box p={3} mb={3} sx={style}>
      <TextField
        fullWidth
        label="Enter Question Here"
        variant="outlined"
        value={questionText}
        onChange={(e) => setQuestionText(e.target.value)}
        sx={{ mb: 2, borderColor: '#F84135', color: '#F84135' }}
      />

      <Box sx={{ width: '100%', backgroundColor: 'white', borderRadius: '15px' }}>
        {questionType === 'Multiple-choice' && (
          <Box>
            <Box display="flex" flexWrap="wrap" gap={2}>
              {options.map((option, index) => (
                <Box key={index} sx={{ width: '45%', display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    control={<Checkbox disabled />}
                    label={
                      <TextField
                        value={option.value}
                        onChange={(e) => handleOptionChange(index, e)}
                        placeholder={`Option ${index + 1}`}
                        variant="outlined"
                        fullWidth
                      />
                    }
                  />
                  <IconButton onClick={() => handleRemoveOption(index)} sx={{ ml: 1 }}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
            <Button onClick={handleAddOption} variant="underlined" sx={{ mt: 2 }}>
              Add More Options
            </Button>
          </Box>
        )}

        {questionType === 'Single-choice' && (
          <RadioGroup>
            <Box display="flex" flexWrap="wrap" gap={2}>
              {options.map((option, index) => (
                <Box key={index} sx={{ width: '45%', display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    control={<Radio disabled />}
                    label={
                      <TextField
                        value={option.value}
                        onChange={(e) => handleOptionChange(index, e)}
                        placeholder={`Option ${index + 1}`}
                        variant="outlined"
                        fullWidth
                      />
                    }
                  />
                  <IconButton onClick={() => handleRemoveOption(index)} sx={{ ml: 1 }}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
            <Button onClick={handleAddOption} variant="underlined" sx={{ mt: 2 }}>
              Add More Options
            </Button>
          </RadioGroup>
        )}

        {questionType === 'Rating' && (
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Select a rating (1 to 5):
            </Typography>
            <RadioGroup row>
              {[1, 2, 3, 4, 5].map((value) => (
                <FormControlLabel
                  key={value}
                  control={<Radio disabled />}
                  label={value}
                />
              ))}
            </RadioGroup>
          </Box>
        )}

        {questionType === 'Agree-disagree' && (
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Do you agree with the statement?
            </Typography>
            <RadioGroup row>
              <FormControlLabel control={<Radio disabled />} label="Yes" />
              <FormControlLabel control={<Radio disabled />} label="No" />
            </RadioGroup>
          </Box>
        )}

        {questionType === 'Short-answer' && (
          <TextField
            label="Your Answer"
            variant="outlined"
            fullWidth
            disabled
            sx={{ mt: 2 }}
          />
        )}

        {questionType === 'Brief-answer' && (

          <TextField
            label="Your Answer"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            disabled
            sx={{ mt: 2 }}
          />

        )}
        {/* toggle button required true or not  */}
        <Box display={'flex'} mt={'10px'} flexDirection={'row'} justifyContent={'start'} alignItems={'center'} gap={'10px'}>

          {/* Required WIll be only visible in case of Survey */}
          {
            questionType === 'Brief-answer' && (
              <TextField
                label="Maximum Words"
                variant="outlined"
                size='small'
                value={ansLength}
                onChange={(e)=>{setAnsLength(e.target.value)}}
                sx={{  width: '20%' }}
              />
            )
          }
          {
            <FormControl >
              <InputLabel id="required-label">Required</InputLabel>
              <Select

                size='small'
                labelId="required-label"
                id="required"
                value={required}
                label="Required"
                onChange={(e) => { setRequired(e.target.value) }}
              >
                <MenuItem value={true}>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </Select>
            </FormControl>
          }
          {questionaireType === 'quiz' && (
            <>
              <Grid item sx={{ width: '10%' }}>
                <TextField
                  size="small"
                  variant="outlined"
                  type="number"
                  label="marks"
                  value={marks}
                  onChange={(e) => setMarks(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item sx={{ width: '20%' }}>
                <TextField
                  size="small"
                  variant="outlined"
                  type="number"
                  label="Negative Marks (i.e:-x)"
                  value={negativeMark}
                  onChange={(e) => {
                    const value = e.target.value;
                    setNegativeMark(value < 0 ? value : -Math.abs(value));
                  }}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item sx={{ width: '15%' }}>
                <FormControl fullWidth size="small">
                  <InputLabel>Correct Answer</InputLabel>
                  <Select
                    value={correctAnswer}
                    onChange={(e) => setCorrectAnswer(e.target.value)}
                    label="Correct Answer"
                  >
                    {options.map((option) => (
                      <MenuItem key={option} value={option?.value}>
                        {option?.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sx={{ width: '15%' }}>
                <TextField
                  size="small"
                  variant="outlined"
                  type="number"
                  label="Attempt Number"
                  value={attemptNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    setAttemptNumber(value);
                  }}
                  required
                  fullWidth
                />
              </Grid>
            </>
          )}
          <Button
            onClick={handleAddQuestion}
            variant="contained"
            color="primary"
            sx={{

              backgroundColor: '#F84135',
              ':hover': {
                backgroundColor: '#F84135',
              },
            }}
          >
            Add Question
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionForm;
