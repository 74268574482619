import React, { useState, useEffect } from 'react';
import { TextField, Button, Paper, Box, Typography, Grid, FormControl, MenuItem, Select, InputLabel, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { apiConnector } from '../../services/ApiConnector';
import { staffEndpoints } from '../../services/ApiEndPoints';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import useAwsFileUpload from '../../hooks/useAwsImageUpload';

const AddStaff = () => {
  const { uploadFileToAws, uploading } = useAwsFileUpload();
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [profileImage, setProfileImage] = useState(null);

  const permissionOptions = ['student-management',
    'school-management',
    'course-management',
    'teacher-management',
    'assessment-management',
    'brand-management',
    'certificate-management',
    'report-management'
    , 'counceller-management'
    , 'notification-management']

  const handleProfileImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage && selectedImage.type.startsWith('image/')) {
      setProfileImage(selectedImage);
    } else {
      toast.error('Only image files are allowed for profile image');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !email || !role || !password || permissions.length === 0) {
      toast.error("All fields are required");
      return;
    }

    const toastId = toast.loading("Submitting staff data...");
    try {
      let profileImageUrl = '';

      // Upload profile image if available
      if (profileImage) {
        profileImageUrl = await uploadFileToAws(profileImage);
        if (!profileImageUrl) throw new Error('Failed to upload profile image');
      }

      const data = {
        name,
        email,
        phone,
        role,
        password,
        permission: permissions,
        profilePhoto: profileImageUrl,
      };

      const response = await apiConnector(
        'POST',
        staffEndpoints.CREATE_STAFF,
        data,
        { Authorization: `Bearer ${token}` }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Staff added successfully!");
      } else {
        toast.error("Failed to add staff. Please try again.");
      }
    } catch (error) {
      toast.error(`Failed to add staff: ${error.message}`);
    } finally {
      toast.dismiss(toastId);
      navigate('/all-staff');
    }
  };

  return (
    <Paper sx={{ width: "80%", margin: "auto", padding: 4 }}>
      <Typography variant="h5" textAlign="center" fontWeight="bold" mb={3}>
        Add Staff
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off" sx={{ display: "flex", flexDirection: "row", gap: 3, width: '100%', flexWrap: 'wrap' }}>
        <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} sx={{ width: '48%' }} required />
        <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} sx={{ width: '48%' }} required />
        <TextField label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} sx={{ width: '48%' }} required />
        <FormControl sx={{ width: '48%' }}>
          <InputLabel>Role</InputLabel>
          <Select value={role} onChange={(e) => setRole(e.target.value)} required>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="sub-admin">Sub-admin</MenuItem>
          </Select>
        </FormControl>
        <TextField label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} sx={{ width: '48%' }} required />


        {
          role != 'admin' &&
          <FormControl fullWidth>
            <InputLabel>Permissions</InputLabel>
            <Select
              multiple
              value={permissions}
              onChange={(e) => setPermissions(e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {permissionOptions.map((permission) => (
                <MenuItem key={permission} value={permission}>
                  {permission}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }

        <label htmlFor="upload-profile-image">
          <input
            id="upload-profile-image"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleProfileImageChange}
          />
          <Button variant="contained" component="span">
            {profileImage ? profileImage.name : 'Choose Profile Image'}
          </Button>
        </label>


        <Box width={'100%'} display="flex" justifyContent="space-between" mt={4}>
          <Button variant="outlined" onClick={() => navigate("/staff")}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Add Staff
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default AddStaff;
